
const token = localStorage.getItem('accessToken');

export const Config = {
    ApiBaseUrl: 'https://wapi.meritnet.com/api',
    Token: token,
    //ApiBaseUrl: 'http://10.160.41.80:85/api',
    //Token: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiI4NjUiLCJuYW1lIjoiZHV5Z3VzIiwidmVyc2lvbiI6IjEiLCJleHAiOjIwMTgwMDk2NzAsImlzcyI6Imh0dHA6Ly9sb2NhbGhvc3QvIiwiYXVkIjoiV2lzZUFwcCJ9.r3L_r8B24X2l20rq45RMtYvAf0lNNwJ51HeZmi1_zkQ",
}

//Token: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIyODYyIiwibmFtZSI6ImR1eWd1LnMiLCJ2ZXJzaW9uIjoiMSIsImV4cCI6MjAyMDQwNzI2NSwiaXNzIjoiaHR0cDovL2xvY2FsaG9zdC8iLCJhdWQiOiJXaXNlQXBwIn0.CYs9SSLvtWM5T76apK9r-ZG7j-08BFdCdod4dvVqFMw"
