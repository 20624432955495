import React, { useState, useEffect, createContext, useContext, useCallback } from 'react';
import { getUser, signIn as sendSignInRequest, authVerifyKey as sendAuthKeyRequest } from '../api/auth';
import type { User, AuthUser, AuthContextType } from '../types';
import { useNavigate } from "react-router-dom";
import notify from 'devextreme/ui/notify';

function AuthProvider(props: React.PropsWithChildren<unknown>) {
  const [user, setUser] = useState<User>();
  const [authUser, setAuthUser] = useState<AuthUser>();
  const [name, setName] = useState<string>();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const savedAccessToken = localStorage.getItem('accessToken');
  const [accessToken, setAccessToken] = useState<string | undefined>(savedAccessToken ?? undefined);

  useEffect(() => {
    (async function () {
      const result = await getUser();
      if (result.isOk) {
        setUser(result.data);
      }
      setLoading(false);
    })();
  }, []);

  const signIn = useCallback(async (username: string, password: string) => {
    const result = await sendSignInRequest(username, password);
    setName(username);
    if (result.isOk) {
      console.log("Giriş başarılı")
      //console.log("Result User",result);
      //setUser(result.data);
    }
    return result;
  }, []);


  const authVerifyKey = useCallback(async (username: string, verificationCode: string) => {
    const result = await sendAuthKeyRequest(username, verificationCode);
    if (result.isOk) {
      const authUser: AuthUser = {
        user: {
          userId: result.data.user.userId,
          departmentId: result.data.user.departmentId,
          groupId: result.data.user.groupId,
          casinoId: result.data.user.casinoId,
          userName: result.data.user.userName,
          fullname: result.data.user.fullname,
          email: result.data.user.email,
          phoneNumber: result.data.user.phoneNumber,
          verificationCode: result.data.user.verificationCode,
          password: result.data.user.password,
          casinoAccess: result.data.user.casinoAccess,
          mobileAccess: result.data.user.mobileAccess,
          lockoutEnabled: result.data.user.lockoutEnabled,
          lockoutEndDate: result.data.user.lockoutEndDate,
          accessFailedCount: result.data.user.accessFailedCount,
          version: result.data.user.version,
        },
        authCasinos: result.data.authCasinos,
        accessToken: result.data.accessToken,
        expiresIn: result.data.expiresIn,
        tokenType: result.data.tokenType,
      };
      setAuthUser(authUser);
      setAccessToken(result.data.accessToken)
    } 
    return result;
  }, []);


  const signOut = useCallback(() => {
    setAuthUser(undefined);
    localStorage.removeItem("accessToken");
    localStorage.removeItem("authCasinos");
    localStorage.removeItem("username");
    localStorage.removeItem("casinosList");
    localStorage.removeItem("selectedCasinoId");
    localStorage.removeItem("searchCustomerId");
    localStorage.removeItem("currentCasinoId");
    setAccessToken("");
    navigate("/login");
  }, []);


  return (
    <AuthContext.Provider value={{ user, name, authUser, signIn, authVerifyKey, signOut, loading, accessToken }} {...props} />
  );
}

const AuthContext = createContext<AuthContextType>({ loading: false } as AuthContextType);
const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth }
