import React, { useState, useEffect, useRef } from "react";
import "./home.scss";
import "./styles.css";
import "@coreui/coreui/dist/css/coreui.min.css";
import DataGrid, { Column, Scrolling, Toolbar, Item, Sorting, Paging, Selection } from "devextreme-react/data-grid"
import { CWidgetStatsA, CWidgetStatsF, CWidgetStatsB } from "@coreui/react";
import {
  CRow,
  CCol,
  CCard,
  CCardBody,
  CCardFooter,
  CProgress,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import {
  cilArrowTop,
  cilArrowBottom,
  cilChartPie,
  cilUser,
  cilDollar,
  cilCash,
  cilMoney,
} from "@coreui/icons";
import { CChart, CChartLine } from "@coreui/react-chartjs";
import { getStyle, hexToRgba } from "@coreui/utils";
import { useGlobal } from "../../contexts/global";
import { useScreenSize } from '../../utils/media-query';
import type { CustomerHotList, CasinoWinloss, CasinosWinlosses } from '../../types';
import { Config } from '../../config/config';
import { LoadIndicator } from 'devextreme-react/load-indicator';
import dayjs from 'dayjs'
import { useAuth } from "../../contexts/auth";
import ScrollView from 'devextreme-react/scroll-view';
import { useLocation } from "react-router-dom";
import { useNavigate } from 'react-router-dom';

interface ProgressItem {
  title: string;
  value: number;
  percent: number;
  color: string;
}

export default function Home() {
  const { currentCasino, dateRange, casinos, changeCasino } = useGlobal();
  const { accessToken } = useAuth();
  const { isXSmall } = useScreenSize();
  const navigate = useNavigate();

  const location = useLocation();
  const loadedDataRef = useRef({ casinoId: -1, startDate: new Date(), endDate: new Date() });

  const [customerHotListWinner, setCustomerHotListWinner] = useState<CustomerHotList[]>([]);
  const [customerHotListLoser, setCustomerHotListLoser] = useState<CustomerHotList[]>([]);
  const [casinoWinloss, setCasinoWinloss] = useState<CasinoWinloss[]>([]);
  const [casinosWinlosses, setCasinosWinlosses] = useState<CasinosWinlosses[]>([]);

  const [totalDrop, setTotalDrop] = useState<number>(0);
  const [totalDiscount, setTotalDiscount] = useState<number>(0);
  const [totalResult, setTotalResult] = useState<number>(0);
  const [totalNetResult, setTotalNetResult] = useState<number>(0);

  const [lgDropPercent, setLgDropPercent] = useState<number>(0);
  const [smDropPercent, setSmDropPercent] = useState<number>(0);
  const [lgResultPercent, setLgResultPercent] = useState<number>(0);
  const [smResultPercent, setSmResultPercent] = useState<number>(0);
  const [cashPercent, setCashPercent] = useState<number>(0);
  const [chipPercent, setChipPercent] = useState<number>(0);

  //Line Chart 
  const [chartLabel, setChartLabel] = useState<Array<string[]>>([]);
  const [chartDrop, setChartDrop] = useState<number[]>([]);
  const [chartResult, setChartResult] = useState<number[]>([]);
  const [chartCredit, setChartCredit] = useState<number[]>([]);
  const [chartCashDisc, setChartCashDisc] = useState<number[]>([]);
  const [chartChipDisc, setChartChipDisc] = useState<number[]>([]);
  const [progressData, setProgressData] = useState<ProgressItem[]>([
    { title: "Drop", value: 0, percent: 0, color: "success" },
    { title: "Result", value: 0, percent: 0, color: "info" },
    { title: "Credit", value: 0, percent: 0, color: "warning" },
    { title: "Cash Discount", value: 0, percent: 0, color: "danger" },
    { title: "Chip Discount", value: 0, percent: 0, color: "primary" },
  ]);

  const [loadIndicatorVisible, setloadIndicatorVisible] = useState<boolean>(false);

  function cellRender(data: any) {
    return (
      <img
        src={`data:image/png;base64,${data.data.photo}`}
        style={{ display: "block", width: 30, height: 30, borderRadius: 15 }}
        onError={(e) => {
          e.currentTarget.src = require("../../assets/images/merit_logo.png");
        }}
      />
    );
  }

  let isFirstLoad = useRef(true);
  // Casino Winloss  All Casinos-Drop/Discount/Result/NetResult
  // http://10.160.41.80:85/api/dashboard/casinowinloss
  async function postCasinoWinloss(): Promise<CasinoWinloss[]> {
    setloadIndicatorVisible(true);

    if (isFirstLoad.current == true) {
      isFirstLoad.current = false;
      if (currentCasino?.casinoId != casinos[0].casinoId) return [];
    }


    const headers: Headers = new Headers()
    headers.set('Content-Type', 'application/json')
    headers.set('Accept', 'application/json')
    headers.set('Authorization', `Bearer ${accessToken}`)

    const request: RequestInfo = new Request(`${Config.ApiBaseUrl}/dashboard/casinowinloss`, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify({
        casinoId: currentCasino?.casinoId,
        reportStartDate: dayjs(dateRange[0]).format('YYYY-MM-DD'),
        reportEndDate: dayjs(dateRange[1]).format('YYYY-MM-DD'),
      }),
    })
    const res = await fetch(request);
    const response = await res.json();
    setCasinoWinloss(response);
    setloadIndicatorVisible(false);
    updateValues([
      parseFloat(response[0]?.casinoDrop.toFixed(0)),
      parseFloat(response[0]?.casinoWinloss.toFixed(0)),
      parseFloat(response[0]?.credit.toFixed(0)),
      parseFloat(response[0]?.discountCash.toFixed(0)),
      parseFloat(response[0]?.discountGame.toFixed(0)),
    ], [
      parseFloat(response[0]?.casinoDropDiff.toFixed(0)),
      parseFloat(response[0]?.casinoWinlossDiff.toFixed(0)),
      parseFloat(response[0]?.creditDiff.toFixed(0)),
      parseFloat(response[0]?.discountCashDiff.toFixed(0)),
      parseFloat(response[0]?.discountGameDiff.toFixed(0)),
    ])
    return response as CasinoWinloss[];
  }

  useEffect(() => {
    if (casinoWinloss && casinoWinloss[0]?.dropListTotal) {
      const totalDrop = parseInt(casinoWinloss[0]?.dropListTotal.toFixed(2));
      setTotalDrop(totalDrop);
    }
    if (casinoWinloss && casinoWinloss[0]?.discountListTotal) {
      const totalDiscount = parseInt(casinoWinloss[0]?.discountListTotal.toFixed(2));
      setTotalDiscount(totalDiscount);
    }
    if (casinoWinloss && casinoWinloss[0]?.winlossListTotal) {
      const totalResult = parseInt(casinoWinloss[0]?.winlossListTotal.toFixed(2));
      setTotalResult(totalResult);
    }
    if (casinoWinloss && casinoWinloss[0]?.netWinlossListTotal) {
      const totalNetResult = parseInt(casinoWinloss[0]?.netWinlossListTotal.toFixed(2));
      setTotalNetResult(totalNetResult);
    }
  }, [casinoWinloss])

  const updateValues = (newValueValues: number[], newPercentValues: number[]) => {
    if (
      newPercentValues.length === progressData.length &&
      newValueValues.length === progressData.length
    ) {
      const updatedData = progressData.map((item, index) => ({
        ...item,
        value: newValueValues[index],
        percent: newPercentValues[index],
      }));
      setProgressData(updatedData);
    } else {
      console.error('Invalid number of values provided for updating percent and value values.');
    }
  };

  useEffect(() => {
    if (casinoWinloss && casinoWinloss[0]?.dropChartLg && casinoWinloss[0]?.dropChartSm) {
      const lgPercent = parseInt(casinoWinloss[0]?.dropChartLg.toFixed(0));
      const smPercent = parseInt(casinoWinloss[0]?.dropChartSm.toFixed(0));
      setLgDropPercent(lgPercent);
      setSmDropPercent(smPercent);
    }
    if (casinoWinloss && casinoWinloss[0]?.resultChartLg && casinoWinloss[0]?.resultChartSm) {
      const lgResultPercent = parseInt(casinoWinloss[0]?.resultChartLg.toFixed(0));
      const smResultPercent = parseInt(casinoWinloss[0]?.resultChartSm.toFixed(0));
      setLgResultPercent(lgResultPercent);
      setSmResultPercent(smResultPercent);
    }
    if (casinoWinloss && casinoWinloss[0]?.discountChartLg && casinoWinloss[0]?.discountChartSm) {
      const cashDiscPercent = parseInt(casinoWinloss[0]?.discountChartLg.toFixed(0));
      const chipDiscPercent = parseInt(casinoWinloss[0]?.discountChartSm.toFixed(0));
      setCashPercent(cashDiscPercent);
      setChipPercent(chipDiscPercent);
    }
  }, [casinoWinloss])


  useEffect(() => {
    if (dayjs(dateRange[0]).isSame(dayjs('1900-01-01')) || dayjs(dateRange[1]).isSame(dayjs('1900-01-01'))) return;

    const fetchData = () => {
      const loadedDataRefCurrent = loadedDataRef.current;

      if (loadedDataRefCurrent != undefined) {
        if (
          loadedDataRefCurrent.casinoId == currentCasino?.casinoId &&
          dayjs(loadedDataRefCurrent.startDate).isSame(dayjs(dateRange[0])) &&
          dayjs(loadedDataRefCurrent.startDate).isSame(dayjs(dateRange[1]))
        ) {
          return;
        }
      }

      if (currentCasino != null) {
        loadedDataRefCurrent.casinoId = currentCasino.casinoId;
        loadedDataRefCurrent.startDate = dateRange[0];
        loadedDataRefCurrent.endDate = dateRange[1];

        postCasinoWinloss();

        if (currentCasino.casinoId > -1) {
          postCasinoWinlosses(currentCasino.casinoId);
          postCustomerHotListWinner(currentCasino.casinoId);
          postCustomerHotListLoser(currentCasino.casinoId);
        }
      }
    };

    fetchData();
  }, [currentCasino, dateRange]);


  function formattedData(data: string) {
    const format = data?.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    return format;
  }

  //Casino Winlosses
  //http://10.160.41.80:85/api/dashboard/casinowinlosses
  async function postCasinoWinlosses(casinoID: number): Promise<CasinosWinlosses[]> {
    const headers: Headers = new Headers();
    headers.set('Content-Type', 'application/json');
    headers.set('Accept', 'application/json');
    headers.set('Authorization', `Bearer ${accessToken}`);

    const request: RequestInfo = new Request(`${Config.ApiBaseUrl}/dashboard/casinowinlosses`, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify({
        casinoId: casinoID,
        reportStartDate: dayjs(dateRange[0]).format('YYYY-MM-DD'),
        reportEndDate: dayjs(dateRange[1]).format('YYYY-MM-DD'),
      }),
    });

    const res = await fetch(request);
    const response = await res.json();

    if (Array.isArray(response)) {
      setCasinosWinlosses(response);
      setChartLabel([]);
      setChartDrop([]);
      setChartResult([]);
      setChartCredit([]);
      setChartCashDisc([]);
      setChartChipDisc([]);

      response.forEach(async (item: any, index: number) => {
        setChartLabel((chartLabel) => [...chartLabel, item?.displayDate]);
        setChartDrop((chartDrop) => [...chartDrop, item?.casinoDrop]);
        setChartResult((chartResult) => [...chartResult, item?.casinoWinloss]);
        setChartCredit((chartCredit) => [...chartCredit, item?.credit]);
        setChartCashDisc((chartCashDisc) => [...chartCashDisc, item?.discountCash]);
        setChartChipDisc((chartChipDisc) => [...chartChipDisc, item.discountGame]);
      });

      return response as CasinosWinlosses[];
    } else {
      // Handle the case where the response is not an array
      console.error("Invalid response format. Expected an array.");
      return [];
    }
  }

  // Customer Hot List Winner
  // http://10.160.41.80:85/api/dashboard/hotlist   isWinner: true
  async function postCustomerHotListWinner(casinoID: number): Promise<CustomerHotList> {
    const headers: Headers = new Headers()
    headers.set('Content-Type', 'application/json')
    headers.set('Accept', 'application/json')
    headers.set('Authorization', `Bearer ${accessToken}`)

    const request: RequestInfo = new Request(`${Config.ApiBaseUrl}/dashboard/hotlist`, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify({
        casinoId: casinoID,
        reportStartDate: dayjs(dateRange[0]).format('YYYY-MM-DD'),
        reportEndDate: dayjs(dateRange[1]).format('YYYY-MM-DD'),
        isWinner: true
      }),
    })

    const res = await fetch(request);
    const response = await res.json();
    setCustomerHotListWinner(response);
    return response as CustomerHotList;
  }

  // Customer Hot List Loser
  // http://10.160.41.80:85/api/dashboard/hotlist   isWinner: false
  async function postCustomerHotListLoser(casinoID: number): Promise<CustomerHotList> {
    const headers: Headers = new Headers()
    headers.set('Content-Type', 'application/json')
    headers.set('Accept', 'application/json')
    headers.set('Authorization', `Bearer ${accessToken}`)

    const request: RequestInfo = new Request(`${Config.ApiBaseUrl}/dashboard/hotlist`, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify({
        casinoId: casinoID,
        reportStartDate: dayjs(dateRange[0]).format('YYYY-MM-DD'),
        reportEndDate: dayjs(dateRange[1]).format('YYYY-MM-DD'),
        isWinner: false
      }),
    })

    const res = await fetch(request);
    const response = await res.json();
    setCustomerHotListLoser(response);
    return response as CustomerHotList;
  }

  const onSelectionChanged = ({ selectedRowsData }: { selectedRowsData: any[] }) => {
    const data = selectedRowsData[0];
    navigate(`/customer/customerdetail?customerId=${data.customerId}`, { state: { data } })
    const id = data.customerId;
    localStorage.setItem("searchCustomerId", id.toString());
  }

  const onCasinoSelectionChanged = ({ selectedRowsData }: { selectedRowsData: any[] }) => {
    const data = selectedRowsData[0];
    console.log("Selected Casino", data.casinoId);
    changeCasino(data.casinoId);
  }

  return (
    <ScrollView showScrollbar="onScroll">
      {(loadIndicatorVisible === false) ?
        (
          <div style={{ marginBottom: 60 }}>
            {/* Casinos */}
            {currentCasino != null && currentCasino.casinoId === 0 &&
              <div>
                <br />
                <DataGrid
                  id="grid"
                  dataSource={casinoWinloss}
                  onSelectionChanged={onCasinoSelectionChanged}
                  keyExpr="casinoId"
                  showBorders={true}
                  //columnHidingEnabled={isXSmall ? true : false}
                  columnAutoWidth={isXSmall ? true : false}
                  allowColumnResizing={isXSmall ? false : true}
                  hoverStateEnabled={true}
                  height="100%"
                  // scrolling={{ mode: 'virtual', showScrollbar: 'never' }}
                  onRowPrepared={(e) => {
                    if (e.rowType === 'data') {
                      e.rowElement.style.cursor = 'pointer';
                    }
                  }}
                >
                  <Sorting mode="single" />
                  <Selection mode="single" />
                  <Toolbar>
                    <Item location='before'>
                      <div className='grid-header'><span style={{ color: "orangered" }}>Casinos</span></div>
                    </Item>
                  </Toolbar>
                  <Column
                    dataField="casinoName"
                    caption="Name"
                  // width={400}
                  />
                  <Column dataField="casinoDropTx" caption="Drop" alignment="right" />
                  <Column dataField="discountTotalTx" caption="Discount" alignment="right" />
                  <Column
                    dataField="netWinlossTx"
                    caption="Net Result"
                    alignment="right"
                  />
                  <Column
                    dataField="sortID"
                    caption="ID"
                    sortOrder="asc"
                    visible={false}
                    width={10}
                  />
                </DataGrid>
              </div>}

            {/* All Casinos / Drop-Discount-Result-Net Result */}
            <br />
            {currentCasino != null && currentCasino.casinoId === 0 &&
              <CRow>
                <CCol xs={6} sm={6} lg={3}>
                  <CWidgetStatsA
                    className="mb-4 pb-3"
                    color="primary"
                    value={
                      <span style={{ fontSize: isXSmall ?  14 : 18 }}>
                        $ {totalDrop && formattedData(totalDrop.toFixed(0))}
                      </span>
                    }
                    title="Drop"
                  />
                </CCol>
                <CCol xs={6} sm={6} lg={3}>
                  <CWidgetStatsA
                    className="mb-4 pb-3"
                    color="info"
                    value={
                      <span style={{ fontSize: isXSmall ?  14 : 18  }}>
                        $ {totalDiscount && formattedData(totalDiscount.toFixed(0))}
                      </span>
                    }
                    title="Discount"
                  />
                </CCol>
                <CCol xs={6} sm={6} lg={3}>
                  <CWidgetStatsA
                    className="mb-4 pb-3"
                    color="warning"
                    value={
                      <span style={{ fontSize: isXSmall ?  14 : 18  }}>
                        $ {totalResult && formattedData(totalResult.toFixed(0))}
                      </span>
                    }
                    title="Result"
                  />
                </CCol>
                <CCol xs={6} sm={6} lg={3}>
                  <CWidgetStatsA
                    className="mb-4 pb-3"
                    color="danger"
                    value={
                      <span style={{ fontSize: isXSmall ?  14 : 18  }}>
                        $ {totalNetResult && formattedData(totalNetResult.toFixed(0))}
                      </span>
                    }
                    title="Net Result"
                  />
                </CCol>
              </CRow>}

            {/* Drop-Result-Net Result-Credit */}
            {/* <br /> */}
            {currentCasino != null && currentCasino.casinoId !== 0 &&
              <CRow>
                <CCol xs={6} sm={6} lg={3}>
                  <CWidgetStatsA
                    className="mb-4 pb-3"
                    color="primary"
                    value={
                      <span style={{ fontSize: isXSmall ?  12 : 18  }}>
                        $ {casinoWinloss[0]?.casinoDrop ? formattedData(casinoWinloss[0]?.casinoDrop.toFixed(0)) : 0}
                        {casinoWinloss[0]?.casinoDropDiff > 0 ? (
                          <span style={{ fontSize: isXSmall ?  12 : 16 }}>
                            ({casinoWinloss[0]?.casinoDropDiff.toFixed(0)}% <CIcon icon={cilArrowTop} height={12} />)
                          </span>
                        ) : (
                          <span style={{ fontSize: isXSmall ?  12 : 16 }}>
                            ({casinoWinloss[0]?.casinoDropDiff.toFixed(0)}% <CIcon icon={cilArrowBottom} height={12} />)
                          </span>
                        )}
                      </span>
                    }
                    title="Drop"
                  />
                </CCol>
                <CCol xs={6} sm={6} lg={3}>
                  <CWidgetStatsA
                    className="mb-4 pb-3"
                    color="info"
                    value={
                      <span style={{ fontSize: isXSmall ?  12 : 18  }}>
                        $ {casinoWinloss[0]?.casinoWinloss ? formattedData(casinoWinloss[0]?.casinoWinloss?.toFixed(0)) : 0}
                        {casinoWinloss[0] && casinoWinloss[0]?.casinoWinlossDiff > 0 ? (
                          <span style={{ fontSize: isXSmall ?  12 : 16 }}>
                            ({casinoWinloss[0]?.casinoWinlossDiff.toFixed(0)}% <CIcon icon={cilArrowTop} height={12} />)
                          </span>
                        ) : (
                          <span style={{ fontSize: isXSmall ?  12 : 16 }}>
                            ({casinoWinloss[0]?.casinoWinlossDiff.toFixed(0)}% <CIcon icon={cilArrowBottom} height={12} />)
                          </span>
                        )}
                      </span>
                    }
                    title="Result"
                  />
                </CCol>
                <CCol xs={6} sm={6} lg={3}>
                  <CWidgetStatsA
                    className="mb-4 pb-3"
                    color="warning"
                    value={
                      <span style={{ fontSize: isXSmall ?  12 : 18  }}>
                        $ {casinoWinloss[0]?.netWinlossT ? formattedData(casinoWinloss[0]?.netWinlossT.toFixed(0)) : 0}
                        {casinoWinloss[0] && casinoWinloss[0]?.netWinlossTDiff > 0 ? (
                          <span style={{ fontSize: isXSmall ?  12 : 16 }}>
                            ({casinoWinloss[0]?.netWinlossTDiff.toFixed(0)}% <CIcon icon={cilArrowTop} height={12} />)
                          </span>
                        ) : (
                          <span style={{ fontSize: isXSmall ?  12 : 16  }}>
                            ({casinoWinloss[0]?.netWinlossTDiff.toFixed(0)}% <CIcon icon={cilArrowBottom} height={12} />)
                          </span>)}
                      </span>
                    }
                    title="Net Result"
                  />
                </CCol>
                <CCol xs={6} sm={6} lg={3}>
                  <CWidgetStatsA
                    className="mb-4 pb-3"
                    color="danger"
                    value={
                      <span style={{ fontSize: isXSmall ?  12 : 18  }}>
                        $ {casinoWinloss[0]?.credit ? formattedData(casinoWinloss[0]?.credit.toFixed(0)) : 0}
                        {casinoWinloss[0] && casinoWinloss[0]?.creditDiff > 0 ? (
                          <span style={{ fontSize: isXSmall ?  12 : 16  }}>
                            ({casinoWinloss[0] && casinoWinloss[0]?.creditDiff.toFixed(0)}% <CIcon icon={cilArrowTop} height={12} />)
                          </span>
                        ) : (
                          <span style={{ fontSize: isXSmall ?  12 : 16 }}>
                            ({casinoWinloss[0]?.creditDiff.toFixed(0)}% <CIcon icon={cilArrowBottom} height={12} />)
                          </span>)}
                      </span>
                    }
                    title="Credit"
                  />
                </CCol>
              </CRow>}

            {/* Visitors-Cash/Chip/Total Discount */}
            {currentCasino != null && currentCasino.casinoId !== 0 &&
              <CRow>
                <CCol xs={12} sm={6} lg={3}>
                  <CWidgetStatsF
                    className="mb-3"
                    color="info"
                    icon={<CIcon icon={cilUser} height={24} />}
                    title="Visitors"
                    value={
                      <span style={{ fontSize: isXSmall ?  14 : 18  }}>
                        {casinoWinloss[0]?.visitor ? casinoWinloss[0]?.visitor.toFixed(0) : 0}
                        {casinoWinloss[0] && casinoWinloss[0]?.visitorDiff > 0 ? (
                          <span style={{ fontSize: 14 }}>
                            ({casinoWinloss[0]?.visitorDiff.toFixed(0)}% <CIcon icon={cilArrowTop} height={12} />)
                          </span>
                        ) : (
                          <span style={{ fontSize: 14 }}>
                            ({casinoWinloss[0]?.visitorDiff.toFixed(0)}% <CIcon icon={cilArrowBottom} height={12} />)
                          </span>
                        )}
                      </span>
                    }
                  />
                </CCol>
                <CCol xs={12} sm={6} lg={3}>
                  <CWidgetStatsF
                    className="mb-3"
                    color="primary"
                    icon={<CIcon icon={cilDollar} height={24} />}
                    title="Cash Discount"
                    value={
                      <span style={{ fontSize: isXSmall ?  14 : 18  }}>
                        {casinoWinloss[0]?.discountCash ? formattedData(casinoWinloss[0]?.discountCash.toFixed(0)) : 0}
                        {casinoWinloss[0] && casinoWinloss[0]?.discountCashDiff > 0 ? (
                          <span style={{ fontSize: 14 }}>
                            ({casinoWinloss[0]?.discountCashDiff.toFixed(0)}% <CIcon icon={cilArrowTop} height={12} />)
                          </span>
                        ) : (
                          <span style={{ fontSize: 14 }}>
                            ({casinoWinloss[0]?.discountCashDiff.toFixed(0)}% <CIcon icon={cilArrowBottom} height={12} />)
                          </span>
                        )}
                      </span>
                    }
                  />
                </CCol>
                <CCol xs={12} sm={6} lg={3}>
                  <CWidgetStatsF
                    className="mb-3"
                    color="warning"
                    icon={<CIcon icon={cilDollar} height={24} />}
                    title="Chip Discount"
                    value={
                      <span style={{ fontSize: isXSmall ?  14 : 18  }}>
                        {casinoWinloss[0]?.discountGame ? formattedData(casinoWinloss[0]?.discountGame.toFixed(0)) : 0}
                        {casinoWinloss[0] && casinoWinloss[0]?.discountGameDiff > 0 ? (
                          <span style={{ fontSize: 14 }}>
                            ({casinoWinloss[0]?.discountGameDiff.toFixed(0)}% <CIcon icon={cilArrowTop} height={12} />)
                          </span>
                        ) : (
                          <span style={{ fontSize: 14 }}>
                            ({casinoWinloss[0]?.discountGameDiff.toFixed(0)}% <CIcon icon={cilArrowBottom} height={12} />)
                          </span>
                        )}
                      </span>
                    }
                  />
                </CCol>
                <CCol xs={12} sm={6} lg={3}>
                  <CWidgetStatsF
                    className="mb-3"
                    color="danger"
                    icon={<CIcon icon={cilDollar} height={24} />}
                    title="Total Discount"
                    value={
                      <span style={{ fontSize: isXSmall ?  14 : 18  }}>
                        {casinoWinloss[0]?.discountTotal ? formattedData(casinoWinloss[0]?.discountTotal.toFixed(0)) : 0}
                        {casinoWinloss[0] && casinoWinloss[0]?.totalDiscountDiff > 0 ? (
                          <span style={{ fontSize: 14 }}>
                            ({casinoWinloss[0]?.totalDiscountDiff.toFixed(0)}% <CIcon icon={cilArrowTop} height={12} />)
                          </span>
                        ) : (
                          <span style={{ fontSize: 14 }}>
                            ({casinoWinloss[0]?.totalDiscountDiff.toFixed(0)}% <CIcon icon={cilArrowBottom} height={12} />)
                          </span>)}
                      </span>
                    }
                  />
                </CCol>
              </CRow>}

            {/* Line Chart */}
            {/* <br /> */}
            {currentCasino != null && currentCasino.casinoId !== 0 &&
              <CCard className="mb-4">
                <CCardBody>
                  <CRow>
                    <CCol sm={5}>
                      <h4 id="traffic" className="card-title mb-0">
                        Traffic
                      </h4>
                      <div className="small text-medium-emphasis">
                        {dayjs(dateRange[0]).format('DD.MM.YYYY')}/{dayjs(dateRange[1]).format('DD.MM.YYYY')}
                      </div>
                    </CCol>
                    <CCol sm={7} className="d-none d-md-block">
                    </CCol>
                  </CRow>
                  <CChartLine
                    style={{ height: "300px", marginTop: "40px" }}
                    data={{
                      labels: chartLabel,
                      datasets: [
                        {
                          label: "Drop",
                          backgroundColor: "transparent",
                          borderColor: getStyle("--cui-success"),
                          pointHoverBackgroundColor: getStyle("--cui-success"),
                          borderWidth: 2,
                          data: chartDrop,
                          fill: true,
                        },
                        {
                          label: "Result",
                          backgroundColor: "transparent",
                          borderColor: getStyle("--cui-info"),
                          pointHoverBackgroundColor: getStyle("--cui-info"),
                          borderWidth: 2,
                          data: chartResult,
                        },
                        {
                          label: "Credit",
                          backgroundColor: "transparent",
                          borderColor: getStyle("--cui-warning"),
                          pointHoverBackgroundColor: getStyle("--cui-warning"),
                          borderWidth: 2,
                          //borderDash: [8, 5],
                          data: chartCredit,
                        },
                        {
                          label: "Cash Discount",
                          backgroundColor: "transparent",
                          borderColor: getStyle("--cui-danger"),
                          pointHoverBackgroundColor: getStyle("--cui-danger"),
                          borderWidth: 2,
                          borderDash: [8, 5],
                          data: chartCashDisc,
                        },
                        {
                          label: "Chip Discount",
                          backgroundColor: "transparent",
                          borderColor: getStyle("--cui-primary"),
                          pointHoverBackgroundColor: getStyle("--cui-primary"),
                          borderWidth: 2,
                          borderDash: [8, 5],
                          data: chartChipDisc,
                        },
                      ],
                    }}
                    options={{
                      maintainAspectRatio: false,
                      plugins: {
                        legend: {
                          display: false,
                        },
                      },
                      scales: {
                        x: {
                          grid: {
                            drawOnChartArea: false,
                          },
                        },
                        y: {
                          ticks: {
                            //beginAtZero: true,
                            maxTicksLimit: 5,
                            stepSize: Math.ceil(250 / 5),
                            //max: 250,
                            // callback: (tickValue, i, ticks) => { // validate number of ticks
                            //   console.log("Ticks",ticks.length);
                            //   console.log("Match", Math.ceil(250 / 5));
                            //   return tickValue;
                            // }
                          },
                        },
                      },
                      elements: {
                        line: {
                          tension: 0.4,
                        },
                        point: {
                          radius: 0,
                          hitRadius: 10,
                          hoverRadius: 4,
                          hoverBorderWidth: 3,
                        },
                      },
                    }}
                  />
                </CCardBody>
                <CCardFooter>
                  <CRow xs={{ cols: 1 }} md={{ cols: 5 }} className="text-center">
                    {progressData.map((item, index) => (
                      <CCol className="mb-sm-2 mb-0" key={index}>
                        <div className="text-medium-emphasis">{item.title}</div>
                        <strong>
                          {item.value} ({item.percent}%)
                        </strong>
                        <CProgress
                          thin
                          className="mt-2"
                          color={`${item.color}`}
                          value={item.value}
                        />
                      </CCol>
                    ))}
                  </CRow>
                </CCardFooter>
              </CCard>}

            {/* Pie Chart */}
            {/* <br /> */}
            {currentCasino != null && currentCasino.casinoId !== 0 &&
              <CRow>
                <CCol xs={6} sm={6} lg={3}>
                  <div className="box-item dx-card responsive-paddings">
                    <strong>Live Game - Slot Drop</strong>
                    <CChart
                      type="doughnut"
                      data={{
                        labels: ["Live Game", "Slot"],
                        datasets: [
                          {
                            backgroundColor: ["#5299e0", "#a7a7a7"],
                            data: [lgDropPercent, smDropPercent],
                          },
                        ],
                      }}
                      options={{
                        plugins: {
                          legend: {
                            labels: {
                              color: getStyle("--cui-body-color"),
                            },
                          },
                        },
                      }}
                    />
                  </div>
                </CCol>
                <CCol xs={6} sm={6} lg={3}>
                  <div className="box-item dx-card responsive-paddings">
                    <strong>Live Game - Slot Result</strong>
                    <CChart
                      type="doughnut"
                      data={{
                        labels: ["Live Game", "Slot"],
                        datasets: [
                          {
                            backgroundColor: ["#DD1B16", "#E46651"],
                            data: [lgResultPercent, smResultPercent],
                          },
                        ],
                      }}
                      options={{
                        plugins: {
                          legend: {
                            labels: {
                              color: getStyle("--cui-body-color"),
                            },
                          },
                        },
                      }}
                    />
                  </div>
                </CCol>
                <CCol xs={6} sm={6} lg={3}>
                  <div className="box-item dx-card responsive-paddings">
                    <strong>Cash - Chip Discount</strong>
                    <CChart
                      type="doughnut"
                      data={{
                        labels: ["Cash", "Chip"],
                        datasets: [
                          {
                            backgroundColor: ["#00D8FF", "#5299e0"],
                            data: [cashPercent, chipPercent],
                          },
                        ],
                      }}
                      options={{
                        plugins: {
                          legend: {
                            labels: {
                              color: getStyle("--cui-body-color"),
                            },
                          },
                        },
                      }}
                    />
                  </div>
                </CCol>
                <CCol xs={6} sm={6} lg={3}>
                  <div className="box-item dx-card responsive-paddings">
                    <strong>Live Game - Slot Drops</strong>
                    <CChart
                      type="doughnut"
                      data={{
                        labels: ["Live Game", "Slot"],
                        datasets: [
                          {
                            backgroundColor: ["#d5a439", "#a7a7a7"],
                            data: [lgDropPercent, smDropPercent],
                          },
                        ],
                      }}
                      options={{
                        plugins: {
                          legend: {
                            labels: {
                              color: getStyle("--cui-body-color"),
                            },
                          },
                        },
                      }}
                    />
                  </div>
                </CCol>
              </CRow>}

            {/* Drop - Result -Marker */}
            <br />
            {currentCasino != null && currentCasino.casinoId !== 0 &&
              <CRow>
                <CCol xs={12} sm={6} lg={4}>
                  <CWidgetStatsF
                    className="mb-3"
                    color="primary"
                    icon={<CIcon icon={cilMoney} height={24} />}
                    title="Live Game Drop"
                    value={
                      <span style={{ fontSize: isXSmall ?  14 : 18  }}>
                        $ {casinoWinloss[0]?.lgDrop ? formattedData(casinoWinloss[0]?.lgDrop.toFixed(0)) : 0}
                        {casinoWinloss[0] && casinoWinloss[0]?.lgDropDiff > 0 ? (
                          <span style={{ fontSize: 14 }}>
                            ({casinoWinloss[0]?.lgDropDiff.toFixed(0)}% <CIcon icon={cilArrowTop} height={12} />)
                          </span>
                        ) : (
                          <span style={{ fontSize: 14 }}>
                            ({casinoWinloss[0]?.lgDropDiff.toFixed(0)}% <CIcon icon={cilArrowBottom} height={12} />)
                          </span>)}
                      </span>
                    }
                  />
                </CCol>
                <CCol xs={12} sm={6} lg={4}>
                  <CWidgetStatsF
                    className="mb-3"
                    color="warning"
                    icon={<CIcon icon={cilCash} height={24} />}
                    title="Slot Drop"
                    value={
                      <span style={{ fontSize: isXSmall ?  14 : 18  }}>
                        $ {casinoWinloss[0]?.smDrop ? formattedData(casinoWinloss[0]?.smDrop.toFixed(0)) : 0}
                        {casinoWinloss[0] && casinoWinloss[0]?.smDropDiff > 0 ? (
                          <span style={{ fontSize: 14 }}>
                            ({casinoWinloss[0]?.smDropDiff.toFixed(0)}% <CIcon icon={cilArrowTop} height={12} />)
                          </span>
                        ) : (
                          <span style={{ fontSize: 14 }}>
                            ({casinoWinloss[0]?.smDropDiff.toFixed(0)}% <CIcon icon={cilArrowBottom} height={12} />)
                          </span>)}
                      </span>
                    }
                  />
                </CCol>
                <CCol xs={12} sm={6} lg={4}>
                  <CWidgetStatsF
                    className="mb-3"
                    color="danger"
                    icon={<CIcon icon={cilChartPie} height={24} />}
                    title="Marker"
                    value={
                      <span style={{ fontSize: isXSmall ?  14 : 18  }}>
                        $ {casinoWinloss[0]?.marker ? formattedData(casinoWinloss[0]?.marker.toFixed(0)) : 0}
                        {casinoWinloss[0] && casinoWinloss[0]?.markerDiff > 0 ? (
                          <span style={{ fontSize: 14 }}>
                            ({casinoWinloss[0]?.markerDiff.toFixed(0)}% <CIcon icon={cilArrowTop} height={12} />)
                          </span>
                        ) : (
                          <span style={{ fontSize: 14 }}>
                            ({casinoWinloss[0]?.markerDiff.toFixed(0)}% <CIcon icon={cilArrowBottom} height={12} />)
                          </span>)}
                      </span>
                    }
                  />
                </CCol>
                <CCol xs={12} sm={6} lg={4}>
                  <CWidgetStatsF
                    className="mb-3"
                    color="primary"
                    icon={<CIcon icon={cilMoney} height={24} />}
                    title="Live Game Result"
                    value={
                      <span style={{ fontSize: isXSmall ?  14 : 18  }}>
                        $ {casinoWinloss[0]?.lgTableWL ? formattedData(casinoWinloss[0]?.lgTableWL.toFixed(0)) : 0}
                        {casinoWinloss[0] && casinoWinloss[0]?.lgTableWLDiff > 0 ? (
                          <span style={{ fontSize: 14 }}>
                            ({casinoWinloss[0]?.lgTableWLDiff.toFixed(0)}% <CIcon icon={cilArrowTop} height={12} />)
                          </span>
                        ) : (
                          <span style={{ fontSize: 14 }}>
                            ({casinoWinloss[0]?.lgTableWLDiff.toFixed(0)}% <CIcon icon={cilArrowBottom} height={12} />)
                          </span>)}
                      </span>
                    }
                  />
                </CCol>
                <CCol xs={12} sm={6} lg={4}>
                  <CWidgetStatsF
                    className="mb-3"
                    color="warning"
                    icon={<CIcon icon={cilCash} height={24} />}
                    title="Slot Result"
                    value={
                      <span style={{ fontSize: isXSmall ?  14 : 18  }}>
                        $ {casinoWinloss[0]?.smWinloss ? formattedData(casinoWinloss[0]?.smWinloss.toFixed(0)) : 0}
                        {casinoWinloss[0] && casinoWinloss[0]?.smWinlossDiff > 0 ? (
                          <span style={{ fontSize: 14 }}>
                            ({casinoWinloss[0]?.smWinlossDiff.toFixed(0)}% <CIcon icon={cilArrowTop} height={12} />)
                          </span>
                        ) : (
                          <span style={{ fontSize: 14 }}>
                            ({casinoWinloss[0]?.smWinlossDiff.toFixed(0)}% <CIcon icon={cilArrowBottom} height={12} />)
                          </span>)}
                      </span>
                    }
                  />
                </CCol>
                <CCol xs={12} sm={6} lg={4}>
                  <CWidgetStatsF
                    className="mb-3"
                    color="danger"
                    icon={<CIcon icon={cilChartPie} height={24} />}
                    title="Missing Chip"
                    value={
                      <span style={{ fontSize: isXSmall ?  14 : 18  }}>
                        $ {casinoWinloss[0]?.missingChip ? formattedData(casinoWinloss[0]?.missingChip.toFixed(0)) : 0}
                        {casinoWinloss[0] && casinoWinloss[0]?.missingChipDiff > 0 ? (
                          <span style={{ fontSize: 14 }}>
                            ({casinoWinloss[0]?.missingChipDiff.toFixed(0)}% <CIcon icon={cilArrowTop} height={12} />)
                          </span>
                        ) : (
                          <span style={{ fontSize: 14 }}>
                            ({casinoWinloss[0]?.missingChipDiff.toFixed(0)}% <CIcon icon={cilArrowBottom} height={12} />)
                          </span>)}
                      </span>
                    }
                  />
                </CCol>
              </CRow>}

            {/* Winner - Loser  */}
            <CRow>
              <CCol xs={12} sm={12} lg={6}>
                <DataGrid
                  id="gridContainer"
                  dataSource={customerHotListWinner}
                  keyExpr="customerId"
                  showBorders={true}
                  // columnWidth={120}
                  onSelectionChanged={onSelectionChanged}
                  columnHidingEnabled={isXSmall ? true : false}
                  columnAutoWidth={isXSmall ? true : false}
                  allowColumnResizing={isXSmall ? false : true}
                  hoverStateEnabled={true}
                  // height="100%"
                  //scrolling={{ mode: 'virtual', showScrollbar: 'never' }}
                  onRowPrepared={(e) => {
                    if (e.rowType === 'data') {
                      e.rowElement.style.cursor = 'pointer';
                    }
                  }}
                >
                  {/* <Scrolling /> */}
                  <Paging defaultPageSize={10} />
                  <Selection mode="single" />
                  <Toolbar>
                    <Item location='before'>
                      <div className='grid-header'><span style={{ color: "orangered" }}>Hot List Winner</span></div>
                    </Item>
                  </Toolbar>
                  <Column
                    dataField=" "
                    width={50}
                    allowSorting={false}
                    cellRender={cellRender}
                  />
                  <Column
                    dataField="fullName"
                    caption="Name"
                    width={150}
                  />
                  { isXSmall &&
                  <Column dataField="customerWL" caption="Result" format="fixedPoint" />
                  }
                  <Column dataField="drop" caption="Drop" format="fixedPoint" />
                  <Column
                    dataField="missingChip"
                    caption="Miss. Chip"
                    format="fixedPoint"
                  />
                  <Column dataField="marker" caption="Marker" format="fixedPoint" />
                  { !isXSmall &&
                  <Column dataField="customerWL" caption="Result" format="fixedPoint" />
                  }
                </DataGrid>
              </CCol>
              {isXSmall && <hr/>}
              <CCol xs={12} sm={12} lg={6}>
                <DataGrid
                  id="gridContainer"
                  dataSource={customerHotListLoser}
                  keyExpr="customerId"
                  showBorders={true}
                  columnHidingEnabled={isXSmall ? true : false}
                  columnAutoWidth={isXSmall ? true : false}
                  allowColumnResizing={isXSmall ? false : true}
                  onSelectionChanged={onSelectionChanged}
                  hoverStateEnabled={true}
                  // height="100%"
                  //scrolling={{ mode: 'virtual', showScrollbar: 'never' }}
                  onRowPrepared={(e) => {
                    if (e.rowType === 'data') {
                      e.rowElement.style.cursor = 'pointer';
                    }
                  }}
                >
                  {/* <Scrolling /> */}
                  <Paging defaultPageSize={10} />
                  <Selection mode="single" />
                  <Toolbar>
                    <Item location='before'>
                      <div className='grid-header'><span style={{ color: "orangered" }}>Hot List Loser</span></div>
                    </Item>
                  </Toolbar>
                  <Column
                    dataField=" "
                    width={50}
                    allowSorting={false}
                    cellRender={cellRender}
                  />
                  <Column
                    dataField="fullName"
                    caption="Name"
                    width={150}
                  />
                   { isXSmall &&
                  <Column dataField="customerWL" caption="Result" format="fixedPoint" />
                  }
                  <Column dataField="drop" caption="Drop" format="fixedPoint" />
                  <Column
                    dataField="missingChip"
                    caption="Miss. Chip"
                    format="fixedPoint"
                  />
                  <Column dataField="marker" caption="Marker" format="fixedPoint" />
                  { !isXSmall &&
                  <Column dataField="customerWL" caption="Result" format="fixedPoint" />
                  }
                </DataGrid>
              </CCol>
            </CRow>
          </div>
        )
        :
        (
          <div style={{ textAlign: 'center', marginTop: 40 }}>
            <LoadIndicator id="medium-indicator" height={40} width={40} />
          </div>
        )}

    </ScrollView>

  )
}
