import defaultUser from '../utils/default-user';
import { Config } from '../config/config';

export async function signIn(username: string, password: string) {
  try {
    // Send request  http://10.160.41.80:85/api/auth/login

    const headers: Headers = new Headers()
    headers.set('Content-Type', 'application/json')
    headers.set('Accept', 'application/json')

    const request: RequestInfo = new Request(`${Config.ApiBaseUrl}/auth/login`, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify({
        username: username,
        password: password,
      }),
    })

    const res = await fetch(request);
    const response = await res.json();  
    if (response === "The verification code sent to your phone and email adress.")
    {
      return {
        isOk: true,
      };
    } else {
      return {
        isOk: false,
        data: "nok_Username or password is invalid."
      };
    }    
  }
  catch {
    return {
      isOk: false,
      message: "Authentication failed"
    };
  }
}

export async function authVerifyKey(username: string, verificationCode: string) {
  try {
    // Send request  http://10.160.41.80:85/api/auth/verify

    const headers: Headers = new Headers()
    headers.set('Content-Type', 'application/json')
    headers.set('Accept', 'application/json')

    const request: RequestInfo = new Request(`${Config.ApiBaseUrl}/auth/verify`, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify({
        username: username,
        verificationCode: verificationCode,
      }),
    })

    const res = await fetch(request);
    const response = await res.json();
    localStorage.setItem("accessToken", response.accessToken);
    // const casinoAuth = JSON.stringify(response.authCasinos);
    localStorage.setItem("authCasinos", response.authCasinos);
    localStorage.setItem("username", response.user.userName);
    //console.log("Response",response);
    return {
      isOk: true,
      data: response
    };
  }
  catch {
    return {
      isOk: false,
      message: "Authentication failed"
    };
  }
}

export async function getUser() {
  try {
    // Send request

    return {
      isOk: true,
      data: defaultUser
    };
  }
  catch {
    return {
      isOk: false
    };
  }
}

export async function createAccount(email: string, password: string) {
  try {
    // Send request
    console.log("Created Account");
    //console.log(email, password);

    return {
      isOk: true
    };
  }
  catch {
    return {
      isOk: false,
      message: "Failed to create account"
    };
  }
}

export async function changePassword(email: string, recoveryCode?: string) {
  try {
    // Send request
    console.log("Changed Password");
    //console.log(email, recoveryCode);

    return {
      isOk: true
    };
  }
  catch {
    return {
      isOk: false,
      message: "Failed to change password"
    }
  }
}

export async function resetPassword(email: string) {
  try {
    // Send request
    console.log("Reset Password");
    //console.log(email);

    return {
      isOk: true
    };
  }
  catch {
    return {
      isOk: false,
      message: "Failed to reset password"
    };
  }
}
