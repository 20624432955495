import React, { useState, useEffect } from 'react';
import DataGrid, {
  Column,
  GroupPanel,
  Paging,
  Summary,
  GroupItem,
  Scrolling,
  Grouping
} from "devextreme-react/data-grid";
import { CWidgetStatsA } from "@coreui/react";
import {
  CRow,
  CCol,
} from "@coreui/react";
import { useScreenSize } from '../../utils/media-query';
import { useGlobal } from "../../contexts/global";
import type { CreditList } from '../../types';
import { Config } from '../../config/config';
import "./styles.css";
import { LoadIndicator } from 'devextreme-react/load-indicator';
import { useAuth } from '../../contexts/auth';

export default function Credit() {
  const { isXSmall, isSmall, isMedium, isLarge } = useScreenSize();
  const [creditList, setCreditList] = useState<CreditList[]>([
    {
      creditID: 0,
      creditDetailID: 0,
      note: "",
      casinoID: 0,
      customerID: 0,
      casinoName: "",
      firstName: "",
      lastName: "",
      reportName: "",
      subName: "",
      currencyID: 0,
      currency: "",
      locationID: 0,
      creditLocation: "",
      creditDate: new Date(),
      redemptionDate: new Date(),
      creditYear: 0,
      creditDay: "",
      creditTypeID: 0,
      creditType: "",
      discount: 0,
      trackerID: 0,
      approvalID: 0,
      status: 0,
      discountType: "",
      creditValue: 0,
      creditValueTx: "",
      encashValue: 0,
      frozenValue: 0,
      passiveValue: 0,
      expensedValue: 0,
      expensedValueTx: "",
      rebateValue: 0,
      rebateValueTx: "",
      barterValue: 0,
      mainCredit: 0,
      mainCreditTx: "",
      mainEncash: 0,
      mainEncashTx: "",
      mainFrozen: 0,
      mainPassive: 0,
      mainExpensed: 0,
      mainRebate: 0,
      mainBarter: 0,
      balance: 0,
      balanceTx: "",
      fullName: "",
      mainCreditTotal: 0,
      mainEnCashTotal: 0,
      mainExpensedTotal: 0,
      balanceTotal: 0,
    }
  ]);
  const [totalCredit, setTotalCredit] = useState<number>(0);
  const [totalReturn, setTotalReturn] = useState<number>(0);
  const [totalExpensed, setTotalExpensed] = useState<number>(0);
  const [totalBalance, setTotalBalance] = useState<number>(0);
  const [loadIndicatorVisible, setloadIndicatorVisible] = useState<boolean>(false);

  const { currentCasino } = useGlobal();
  const { accessToken } = useAuth();
  

  function formattedData(data: string) {
    const format = data.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    return format;
  }

  // Credit List
  // http://10.160.41.80:85/api/casinostatus/getcreditlist?casinoId=4
  async function getCreditList(casinoId: number): Promise<CreditList[]> {
    setloadIndicatorVisible(true);
    const headers: Headers = new Headers()
    headers.set('Content-Type', 'application/json')
    headers.set('Accept', 'application/json')
    headers.set('Authorization', `Bearer ${accessToken}`)

    const request: RequestInfo = new Request(`${Config.ApiBaseUrl}/casinostatus/getcreditlist?casinoId=${casinoId}`, {
      method: 'GET',
      headers: headers,
    })

    const res = await fetch(request);
    const response = await res.json();
    setCreditList(response);
    setloadIndicatorVisible(false);
    return response as CreditList[];
  }

  useEffect(() => {
    if (creditList) {
      setTotalCredit(creditList[0]?.mainCreditTotal);
      setTotalReturn(creditList[0]?.mainEnCashTotal);
      setTotalExpensed(creditList[0]?.mainExpensedTotal);
      setTotalBalance(creditList[0]?.balanceTotal);
    }
  }, [creditList])

  useEffect(() => {
    if (currentCasino != null && currentCasino.casinoId != 0) {
      getCreditList(currentCasino.casinoId);
    }
  }, [currentCasino])


  return (
    <React.Fragment>
      {(loadIndicatorVisible === false) ? (
        <div style={{ width: "100%", marginBottom: 60 }}>
          <br />
          <CRow>
            <CCol xs={6} sm={6} md={6} lg={3}>
              <CWidgetStatsA
                className="mb-4 pb-3"
                color="primary"
                value={
                  <span style={{ fontSize: 16}}>
                    $ {totalCredit && formattedData(totalCredit.toFixed(0))}
                  </span>
                }
                title="Credit"
              />
            </CCol>
            <CCol xs={6} sm={6} md={6} lg={3}>
              <CWidgetStatsA
                className="mb-4 pb-3"
                color="info"
                value={
                  <span style={{ fontSize: 16}}>
                    $ {totalReturn && formattedData(totalReturn.toFixed(0))}
                  </span>
                }
                title="Return"
              />
            </CCol>
            <CCol xs={6} sm={6} md={6} lg={3}>
              <CWidgetStatsA
                className="mb-4 pb-3"
                color="warning"
                value={
                  <span style={{ fontSize: 16}}>
                    $ {totalExpensed && formattedData(totalExpensed.toFixed(0))}
                  </span>
                }
                title="Expensed"
              />
            </CCol>
            <CCol xs={6} sm={6} md={6} lg={3}>
              <CWidgetStatsA
                className="mb-4 pb-3"
                color="danger"
                value={
                  <span style={{ fontSize: 16}}>
                    $ {totalBalance && formattedData(totalBalance.toFixed(0))}
                  </span>
                }
                title="Balance"
              />
            </CCol>
          </CRow>
          <br />
          <DataGrid
            dataSource={creditList}
            keyExpr="creditID"
            allowColumnReordering={false}
            showBorders={true}
            columnHidingEnabled={isXSmall ? true : false}
            columnAutoWidth={isXSmall ? true : false}
            allowColumnResizing={isXSmall ? false : true}
            width='100%'
            height="100%"
            scrolling={{ mode: 'virtual', showScrollbar: 'never' }}
          >
            <GroupPanel visible={false} />
            <Grouping autoExpandAll={false} />
            <Paging defaultPageSize={10} />
            {/* <Scrolling /> */}

            <Column dataField="creditYear" caption="" dataType="string" groupIndex={0} 
            sortOrder="desc"  // Başlangıç sıralama düzenini azalan olarak ayarlar
            sortIndex={0}    // Başlangıç sıralama indeksini 0'a ayarlar (ilk sütun)
            calculateSortValue={(data) => -parseInt(data.creditYear)} // Özel sıralama işlevi
            />
            <Column dataField="creditDay" caption="Credit Day" dataType="date" minWidth={100} />
            <Column dataField="casinoName" caption="Casino" dataType="string" minWidth={120} />
            <Column dataField="fullName" caption="Customer" dataType="string" minWidth={170} />
            <Column dataField="creditType" caption="Type" dataType="string" />
            <Column dataField="currency" caption="Currency" dataType="string" />
            <Column dataField="mainCredit" caption="Credit" format="fixedPoint" minWidth={120} />
            <Column dataField="mainEncash" caption="Return" format="fixedPoint" minWidth={120} />
            <Column dataField="mainExpensed" caption="Expensed" format="fixedPoint" minWidth={100} />
            <Column dataField="discount" caption="Discount" format="fixedPoint" minWidth={100} />
            <Column dataField="balance" caption="Balance" format="fixedPoint" minWidth={120} />

            <Summary>
              <GroupItem
                column="mainCredit"
                summaryType="sum"
                displayFormat="{0}"
                valueFormat="fixedPoint"
                alignByColumn={true}
              />
              <GroupItem
                column="mainEncash"
                summaryType="sum"
                displayFormat="{0}"
                valueFormat="fixedPoint"
                alignByColumn={true}
              />
              <GroupItem
                column="mainExpensed"
                summaryType="sum"
                displayFormat="{0}"
                valueFormat="fixedPoint"
                alignByColumn={true}
              />
              <GroupItem
                column="discount"
                summaryType="sum"
                displayFormat="{0}"
                valueFormat="fixedPoint"
                alignByColumn={true}
              />
              <GroupItem
                column="balance"
                summaryType="sum"
                displayFormat="{0}"
                valueFormat="fixedPoint"
                alignByColumn={true}
              />
            </Summary>
          </DataGrid>
        </div>
      ) : (
        <div style={{ textAlign: 'center', marginTop: 40 }}>
          <LoadIndicator id="medium-indicator" height={40} width={40} />
        </div>
      )}
    </React.Fragment>
  );
}
