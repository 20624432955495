import React, { useState, createContext, useContext, useEffect } from 'react';
import type { Casino, GlobalContextType } from '../types';
import { Config } from '../config/config';
import { useLocation } from 'react-router-dom';
import dayjs from 'dayjs'
import { useAuth } from './auth';

const GlobalContext = createContext<GlobalContextType>({} as GlobalContextType);
const useGlobal = () => useContext(GlobalContext);

function GlobalProvider(props: React.PropsWithChildren<unknown>) {
  const location = useLocation()
  const { accessToken } = useAuth();

  const [casinos, setCasinos] = useState<Casino[]>([]);
  const [currentCasino, setCurrentCasino] = useState<Casino | undefined>();
  const [dateRange, setDateRange] = useState<Date[]>([new Date(1900, 0, 1), new Date(1900, 0, 1)]);
  const [loading, setLoading] = useState<boolean>(false);

  const changeCasino = async (value: number) => {
    if (isNaN(value) || (currentCasino != null && currentCasino.casinoId == value)) return;

    const casinoId = value.toString();

    if (value > 0) {
      localStorage.setItem("currentCasinoId", casinoId)
    }

    const casino = casinos.find(x => x.casinoId === value);
    setCurrentCasino(casino);
    //await getCasinoDate(value)
  }

  const setSelectedCasinoDate = (casinoId: number, date: Date) => {
    setCasinos((prevCasinos) => {
      return prevCasinos.map(x =>
        x.casinoId === casinoId ? { ...x, activeDate: date } : x
      );
    });
  }

  //Get All Casinos
  async function loadCasinos(): Promise<Casino[]> {
    if (loading) return [];
    setLoading(true)
    const headers: Headers = new Headers()
    headers.set('Content-Type', 'application/json')
    headers.set('Accept', 'application/json')
    headers.set('Authorization', `Bearer ${accessToken}`)

    const request: RequestInfo = new Request(`${Config.ApiBaseUrl}/casino/listbypermission`, {
      method: 'GET',
      headers: headers
    })

    const res = await fetch(request);
    const response = await res.json();

    const updatedCasinos: Casino[] = [...response];
    updatedCasinos.unshift({ casinoId: 0, name: 'All Casinos', status: 1, activeDate: new Date() });
    setCasinos(updatedCasinos);

    const casino = updatedCasinos[0];
    setCurrentCasino(casino);
    await getCasinoDate(casino.casinoId)

    setLoading(false)
    return updatedCasinos;
  }

  //Get CasinoDate  
  async function getCasinoDate(casinoId: number): Promise<string> {
    casinoId = (casinoId == 0) ? 4 : casinoId;

    const headers: Headers = new Headers()
    headers.set('Content-Type', 'application/json')
    headers.set('Accept', 'application/json')
    headers.set('Authorization', `Bearer ${accessToken}`)

    const request: RequestInfo = new Request(`${Config.ApiBaseUrl}/casino/casinodate?casinoId=` + casinoId, {
      method: 'GET',
      headers: headers
    })

    const res = await fetch(request);
    const response = await res.json();
    const date = dayjs(response, 'YYYY-MM-DD').toDate();

    setSelectedCasinoDate(casinoId, date);

    setDateRange([date, date]);

    return response as string;
  }

  useEffect(() => {
    if (accessToken == undefined || accessToken.length == 0) return;

    const loadCasinosData = async () => {
      await loadCasinos();

      let casinoId = parseInt(localStorage.getItem("currentCasinoId") ?? '');
      if (isNaN(casinoId)) {
        casinoId = 0;
      }
           
    
    };

    loadCasinosData();
  }, [accessToken]);

  return (
    <GlobalContext.Provider
      value={{
        casinos,
        currentCasino,
        dateRange,
        setDateRange,
        changeCasino
      }}
      {...props}
    />
  );
}

export {
  GlobalProvider,
  useGlobal,
}
