import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import DataGrid, {
  Column,
  Selection,
  Paging
} from "devextreme-react/data-grid";

import { CWidgetStatsA } from "@coreui/react";
import {
  CRow,
  CCol,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import {
  cilCaretLeft,
} from "@coreui/icons";

import { useNavigate } from 'react-router-dom';
import { useScreenSize } from '../../../utils/media-query';
import type { CustomerTypes } from '../../../types.js';
import { Config } from '../../../config/config';
import { useGlobal } from "../../../contexts/global";
import { LoadIndicator } from 'devextreme-react/load-indicator';
import dayjs from 'dayjs';

export default function TableDetail({ }: React.PropsWithChildren) {
  const { currentCasino, dateRange } = useGlobal();
  const [tableCustomer, setTableCustomer] = useState<CustomerTypes[]>([]);
  const [totalDrop, setTotalDrop] = useState<number>(0);
  const [totalResult, setTotalResult] = useState<number>(0);

  const [loadIndicatorVisible, setloadIndicatorVisible] = useState<boolean>(false);

  const navigate = useNavigate();
  const location = useLocation();
  const data = location.state.data;
  const gameTypeId = data.gameTypeId;
  const tableId = data.tableId;

  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get('tableId');

  const { isXSmall } = useScreenSize();

  const token = localStorage.getItem('accessToken');


  const onSelectionChanged = ({ selectedRowsData }: { selectedRowsData: any[] }) => {
    const data = selectedRowsData[0];
    //navigate(`/customer/${data.customerId}/`, { state: { data } })
    navigate(`/customer/customerdetail?customerId=${data.customerId}`, { state: { data } })
    const id = data.customerId;
    localStorage.setItem("searchCustomerId", id.toString());

  }

  function cellRender(data: any) {
    return (
      <img
        src={`data:image/png;base64,${data.value}`}
        style={{ display: "block", width: 30, height: 30, borderRadius: 15 }}
      />
    );
  }

  const back = () => {
    navigate(-1);
  }

  //Get Table Customer
  async function getTableCustomer(casinoId: number): Promise<CustomerTypes> {
    setloadIndicatorVisible(true);
    const headers: Headers = new Headers()
    headers.set('Content-Type', 'application/json')
    headers.set('Accept', 'application/json')
    headers.set('Authorization', `Bearer ${token}`)

    const request: RequestInfo = new Request(`${Config.ApiBaseUrl}/game/gettablecustomertotal?casinoId=${casinoId}&gameTypeId=${gameTypeId}&tableId=${tableId}&reportStartDate=${dayjs(dateRange[0]).format('YYYY-MM-DD')}&reportEndDate=${dayjs(dateRange[1]).format('YYYY-MM-DD')}`, {
      method: 'GET',
      headers: headers
    })

    const res = await fetch(request);
    const response = await res.json();
    setTableCustomer(response);
    setTotalDrop(response[0]?.tableDropTotal);
    setTotalResult(response[0]?.resultTotal);
    setloadIndicatorVisible(false);
    return response as CustomerTypes;
  }

  useEffect(() => {
    if (currentCasino != null && currentCasino.casinoId !== 0) {
      getTableCustomer(currentCasino.casinoId)
    }
  }, [currentCasino, dateRange])

  function formattedData(data: string) {
    const format = data?.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    return format;
  }

  return (
    <React.Fragment>
      {(loadIndicatorVisible === false) ?
        (
          <div>
            <br />
            <CRow>         
              <div className='col-lg-1 col-1'><CIcon icon={cilCaretLeft} size="lg" id="clearBtn" onClick={back} /></div>
              <div className='col-lg-11 col-11'><h6>Table Name: {data.tableName}</h6></div>
            </CRow>
            <br />
            <div>
              <CRow>
                <CCol xs={6} sm={6} lg={6}>
                  <CWidgetStatsA
                    className="mb-4 pb-3"
                    color="primary"
                    value={
                      <span style={{ fontSize: 16}}>
                        $ {formattedData(totalDrop?.toFixed(0).toString())}
                      </span>
                    }
                    title="Drop"
                  />
                </CCol>
                <CCol xs={6} sm={6} lg={6}>
                  <CWidgetStatsA
                    className="mb-4 pb-3"
                    color="info"
                    value={
                      <span style={{ fontSize: 16}}>
                        $ {formattedData(totalResult?.toFixed(0).toString())}
                      </span>
                    }
                    title="Result"
                  />
                </CCol>
              </CRow>
            </div>        
            <DataGrid
              id="grid"
              dataSource={tableCustomer}
              keyExpr="customerId"
              allowColumnReordering={false}
              showBorders={true}
              onSelectionChanged={onSelectionChanged}
              hoverStateEnabled={true}
              columnHidingEnabled={isXSmall ? true : false}
              columnAutoWidth={isXSmall ? true : false}
              allowColumnResizing={isXSmall ? false : true}
              height="100%"
              scrolling={{ mode: 'virtual', showScrollbar: 'never' }}
              onRowPrepared={(e) => {
                if (e.rowType === 'data') {
                  e.rowElement.style.cursor = 'pointer';
                }
              }}            
            >
              <Paging defaultPageSize={8} />
              <Selection mode="single" />
              <Column
                dataField="photo"
                caption=" "
                width={50}
                allowSorting={false}
                cellRender={cellRender}
              />
              <Column dataField="customerName" caption="Customer Name" />
              <Column dataField="tableDrop" caption="Table Drop" format="fixedPoint" />
              <Column dataField="result" caption="Result" format="fixedPoint" />
            </DataGrid>
            <br />
          </div>
        ) : (
          <div style={{ textAlign: 'center', marginTop: 40 }}>
            <LoadIndicator id="medium-indicator" height={40} width={40} />
          </div>
        )}
    </React.Fragment>
  );
}

