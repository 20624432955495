import React, { useMemo } from 'react';
import { useNavigate } from "react-router-dom";
import ContextMenu, { Position } from 'devextreme-react/context-menu';
import List from 'devextreme-react/list';
import { useAuth } from '../../contexts/auth';
import './UserPanel.scss';
import type { UserPanelProps } from '../../types';

export default function UserPanel({ menuMode }: UserPanelProps) {
  const { user, authUser, signOut } = useAuth();
  const navigate = useNavigate();

  const username = localStorage.getItem('username');

  function navigateToLogin() {
    signOut();
    navigate("/login");
  }

  // const menuItems = useMemo(() => ([
  //   // {
  //   //   text: 'Profile',
  //   //   icon: 'user',
  //   //   onClick: navigateToProfile
  //   // },
  //   {
  //     text: 'Logout',
  //     icon: 'runner',
  //     onClick: signOut
  //   }
  // ]), [signOut]);

  
  return (
    <div className={'user-panel'}>
      <div className={'user-info'} onClick={navigateToLogin}>
       
        
         <div className={'image-container'}>
          <img src={require("../../assets/images/logout.png")} style={{width: 25, height: 25}}/>         
        </div>
        <div className={'user-name'} style={{color:"white"}}>{username}</div>
        {/* <div className={'user-name'}>{authUser?.user.userName}</div> */}
      </div>

      {/* {menuMode === 'context' && (
        <ContextMenu
          items={menuItems}
          target={'.user-button'}
          showEvent={'dxclick'}
          width={210}
          cssClass={'user-menu'}
        >
          <Position my={{ x: 'center', y: 'top' }} at={{ x: 'center', y: 'bottom' }} />
        </ContextMenu>
      )}
      {menuMode === 'list' && (
        <List className={'dx-toolbar-menu-action'} items={menuItems} />
      )} */}
    </div>
  );
}
