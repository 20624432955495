import React, { useState, useEffect, useCallback } from "react";
import "./customerdetail.css";

import {
  CRow,
  CCol,
  CCard,
  CCardHeader,
  CCardBody,
  CCardTitle,
  CCardText,
  CTable,
  CTableHead,
  CTableDataCell,
  CTableRow,
  CTableHeaderCell,
  CTableBody,
} from "@coreui/react";
import TabPanel, { Item } from "devextreme-react/tab-panel";
import CIcon from '@coreui/icons-react';
import { CWidgetStatsF } from "@coreui/react";
import type { CustomerInfo, CustomerReservations, CustomerDiscount, CustomerJunketWinloss, CreditList, CustomerWinlossDaily, CustomerWinlossMonthly, CustomerWinlossYearly, CustomerPhoto } from '../../../types.js';
import { Config } from '../../../config/config';
import { useGlobal } from "../../../contexts/global";
import logo from '../../../assets/images/merit_logo.png';

import DataGrid, {
  Column,
  Paging,
  Summary,
  TotalItem,
  GroupItem,
  GroupPanel,
  Grouping
} from "devextreme-react/data-grid";

import {
  cilChartPie,
  cilCaretLeft,
} from "@coreui/icons";
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useAuth } from "../../../contexts/auth";

interface SearchBarProps {
  placeholder: string;
  data: SearchResult[];
}


type SearchResult = {
  CustomerId: number;
  Name: string;
  CashOut: number;
  Drop: number;
  Identity: number;
  Nationality: string;
  NetWinloss: number;
  Picture: string;
  Result: number;
  Winloss: number;
}

const CustomerDetail: React.FC<SearchBarProps> = ({ placeholder, data }) => {
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [animationEnabled, setAnimationEnabled] = useState(true)
  const [customerInfo, setCustomerInfo] = useState<CustomerInfo>();
  const [customerReservations, setCustomerReservations] = useState<CustomerReservations[]>([]);
  const [customerDiscount, setCustomerDiscount] = useState<CustomerDiscount[]>([]);
  const [customerJunketWinloss, setCustomerJunketWinloss] = useState<CustomerJunketWinloss[]>([]);
  const [customerCreditList, setCustomerCreditList] = useState<CreditList[]>([]);
  const [customerWinlossYearly, setCustomerWinlossYearly] = useState<CustomerWinlossYearly[]>([]);

  const [selectedTab, setSelectedTab] = useState<string>("Winloss");

  const [totalLgDrop, setTotalLgDrop] = useState<number>(0);
  const [totalSmDrop, setTotalSmDrop] = useState<number>(0);
  const [totalDrop, setTotalDrop] = useState<number>(0);
  const [totalCashDiscount, setTotalCashDiscount] = useState<number>(0);
  const [totalChipDiscount, setTotalChipDiscount] = useState<number>(0);
  const [totalDiscount, setTotalDiscount] = useState<number>(0);
  const [totalLgOut, setTotalLgOut] = useState<number>(0);
  const [totalSmOut, setTotalSmOut] = useState<number>(0);
  const [totalOut, setTotalOut] = useState<number>(0);
  const [totalLgWinloss, setTotalLgWinloss] = useState<number>(0);
  const [totalSmWinloss, setTotalSmWinloss] = useState<number>(0);
  const [totalWinloss, setTotalWinloss] = useState<number>(0);
  const [totalNetWinloss, setTotalNetWinloss] = useState<number>(0);

  const navigate = useNavigate();
  const location = useLocation();
  const value = location.state.data;

  const customerId = value.customerId;

  const { currentCasino } = useGlobal();
  const { accessToken } = useAuth();

  //Get Customer Info
  // http://10.160.41.80:85/api/customer/info?casinoId=4&customerId=94009
  async function getCustomerInfo(casinoId: number, customerId: number): Promise<CustomerInfo> {
    const headers: Headers = new Headers()
    headers.set('Content-Type', 'application/json')
    headers.set('Accept', 'application/json')
    headers.set('Authorization', `Bearer ${accessToken}`)

    const request: RequestInfo = new Request(`${Config.ApiBaseUrl}/customer/info?casinoId=${casinoId}&customerId=${customerId}`, {
      method: 'GET',
      headers: headers
    })

    const res = await fetch(request);
    const response = await res.json();
    setCustomerInfo(response);
    return response as CustomerInfo;
  }

  useEffect(() => {
    if (currentCasino != null && currentCasino.casinoId != 0) {
      getCustomerInfo(currentCasino.casinoId, customerId)
    } else {
      getCustomerInfo(0, customerId)
    }
  }, [currentCasino])

  //Get Customer Reservations
  // http://10.160.41.80:85/api/customer/getreservations?casinoId=4&customerId=94009
  async function getCustomerReservations(casinoId: number, customerId: number): Promise<CustomerReservations> {
    const headers: Headers = new Headers()
    headers.set('Content-Type', 'application/json')
    headers.set('Accept', 'application/json')
    headers.set('Authorization', `Bearer ${accessToken}`)

    const request: RequestInfo = new Request(`${Config.ApiBaseUrl}/customer/getreservations?casinoId=${casinoId}&customerId=${customerId}`, {
      method: 'GET',
      headers: headers
    })

    const res = await fetch(request);
    const response = await res.json();
    setCustomerReservations(response);
    return response as CustomerReservations;
  }

  // useEffect(() => {
  //   getCustomerReservations(casinoId, customerId)
  // }, [casinoId])


  //Get Customer Discount
  // http://10.160.41.80:85/api/customer/getcustomerdiscount?casinoId=4&customerId=94009
  async function getCustomerDiscount(casinoId: number, customerId: number): Promise<CustomerDiscount> {
    const headers: Headers = new Headers()
    headers.set('Content-Type', 'application/json')
    headers.set('Accept', 'application/json')
    headers.set('Authorization', `Bearer ${accessToken}`)

    const request: RequestInfo = new Request(`${Config.ApiBaseUrl}/customer/getcustomerdiscount?casinoId=${casinoId}&customerId=${customerId}`, {
      method: 'GET',
      headers: headers
    })

    const res = await fetch(request);
    const response = await res.json();
    setCustomerDiscount(response);
    return response as CustomerDiscount;
  }

  // useEffect(() => {
  //   getCustomerDiscount(casinoId, customerId)
  // }, [casinoId])


  //Get Customer Junket Winloss
  // http://10.160.41.80:85/api/customer/getjunketwinloss?casinoId=4&customerId=94009  
  async function getCustomerJunketWinloss(casinoId: number, customerId: number): Promise<CustomerJunketWinloss> {
    const headers: Headers = new Headers()
    headers.set('Content-Type', 'application/json')
    headers.set('Accept', 'application/json')
    headers.set('Authorization', `Bearer ${accessToken}`)

    const request: RequestInfo = new Request(`${Config.ApiBaseUrl}/customer/getjunketwinloss?casinoId=${casinoId}&customerId=${customerId}`, {
      method: 'GET',
      headers: headers
    })

    const res = await fetch(request);
    const response = await res.json();
    setCustomerJunketWinloss(response);
    //console.log("Junket List",response)
    return response as CustomerJunketWinloss;
  }

  // useEffect(() => {
  //   getCustomerJunketWinloss(casinoId, customerId)
  // }, [casinoId])


  // Customer Credit List
  // http://10.160.41.80:85/api/customer/getcredithistory?customerId=94009
  async function getCustomerCreditList(customerId: number): Promise<CreditList[]> {
    const headers: Headers = new Headers()
    headers.set('Content-Type', 'application/json')
    headers.set('Accept', 'application/json')
    headers.set('Authorization', `Bearer ${accessToken}`)

    const request: RequestInfo = new Request(`${Config.ApiBaseUrl}/customer/getcredithistory?customerId=${customerId}`, {
      method: 'GET',
      headers: headers,
    })

    const res = await fetch(request);
    const response = await res.json();
    setCustomerCreditList(response);
    return response as CreditList[];
  }

  // useEffect(() => {
  //   getCustomerCreditList(customerId);
  // }, [customerId]);


  // Get Customer Winloss Yearly
  // http://10.160.41.80:85/api/customer/getcustomerwinlossyearly?casinoId=4&customerId=94009 
  async function getCustomerWinlossYearly(casinoId: number, customerId: number): Promise<CustomerWinlossYearly> {
    const headers: Headers = new Headers()
    headers.set('Content-Type', 'application/json')
    headers.set('Accept', 'application/json')
    headers.set('Authorization', `Bearer ${accessToken}`)

    const request: RequestInfo = new Request(`${Config.ApiBaseUrl}/customer/getcustomerwinlossyearly?casinoId=${casinoId}&customerId=${customerId}`, {
      method: 'GET',
      headers: headers
    })

    const res = await fetch(request);
    const response = await res.json();
    setCustomerWinlossYearly(response);
    return response as CustomerWinlossYearly;
  }

  useEffect(() => {
    if (customerWinlossYearly) {
      setTotalLgDrop(customerWinlossYearly[0]?.lgDropTotal);
      setTotalSmDrop(customerWinlossYearly[0]?.smDropTotal);
      setTotalDrop(customerWinlossYearly[0]?.dropTotal);
      setTotalLgOut(customerWinlossYearly[0]?.lgOutTotal);
      setTotalSmOut(customerWinlossYearly[0]?.smOutTotal);
      setTotalOut(customerWinlossYearly[0]?.totalOutTotal);
      setTotalCashDiscount(customerWinlossYearly[0]?.discountCashTotal);
      setTotalChipDiscount(customerWinlossYearly[0]?.discountGameTotal);
      setTotalDiscount(customerWinlossYearly[0]?.totalDiscountTotal);
      setTotalLgWinloss(customerWinlossYearly[0]?.lgWinlossTotal);
      setTotalSmWinloss(customerWinlossYearly[0]?.smWinlossTotal);
      setTotalWinloss(customerWinlossYearly[0]?.winlossTotal);
      setTotalNetWinloss(customerWinlossYearly[0]?.netWinlossTotal);
    }
  }, [customerWinlossYearly])

  useEffect(() => {
    if (currentCasino != null && currentCasino.casinoId !== 0) {
      getCustomerWinlossYearly(currentCasino.casinoId, customerId)
    } else {
      getCustomerWinlossYearly(0, customerId)
    }
  }, [currentCasino])

  function formattedData(data: string) {
    const format = data.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    return format;
  }

  const tabNames = ['Winloss', 'Junket', 'Reservation', 'Discount', 'Credit'];

  const onTabSelectionChanged = useCallback((e: any) => {
    if (e.addedItems[0].title === 'Winloss') {
      setSelectedTab("Winloss");
    } else if (e.addedItems[0].title === 'Junket') {
      setSelectedTab("Junket");
    }
    else if (e.addedItems[0].title === 'Reservation') {
      setSelectedTab("Reservation");
    }
    else if (e.addedItems[0].title === 'Discount') {
      setSelectedTab("Discount");
    }
    else if (e.addedItems[0].title === 'Credit') {
      setSelectedTab("Credit");
    }
    setSelectedIndex(tabNames.indexOf(e.addedItems[0].title));
  }, [currentCasino]);

  useEffect(() => {
    if(currentCasino == null) return;

    if (selectedTab === "Winloss") {
      if (currentCasino.casinoId !== 0) {
        getCustomerWinlossYearly(currentCasino.casinoId, customerId)
      } else {
        getCustomerWinlossYearly(0, customerId)
      }
    } else if (selectedTab === "Junket") {
      if (currentCasino.casinoId !== 0) {
        getCustomerJunketWinloss(currentCasino.casinoId, customerId)
      } else {
        getCustomerJunketWinloss(0, customerId)
      }
    } else if (selectedTab === "Reservation") {
      if (currentCasino.casinoId !== 0) {
        getCustomerReservations(currentCasino.casinoId, customerId)
      } else {
        getCustomerReservations(0, customerId)
      }
    } else if (selectedTab === "Discount") {
      if (currentCasino.casinoId !== 0) {
        getCustomerDiscount(currentCasino.casinoId, customerId)
      } else {
        getCustomerDiscount(0, customerId)
      }
    } else if (selectedTab === "Credit") {
      getCustomerCreditList(customerId);
    }
  }, [currentCasino, selectedTab])

  const back = () => {
    navigate(-1);
  }

  return (
    <div style={{ marginBottom: 60 }}>
      <br />
      <CRow>
        {
          location.pathname.indexOf('/customerdetail') > -1 ? (
            <div className='col-lg-12 col-12'><h6>Custumer Details</h6></div>
          ) : (
            <CRow>
              <div className='col-lg-1 col-2'><CIcon icon={cilCaretLeft} size="lg" id="clearBtn" onClick={back} /></div>
              <div className='col-lg-11 col-10'><h6>Customer Details</h6></div>
            </CRow>
          )
        }
      </CRow>
      <br />
      <CCard>
        <CCardHeader style={{ height: 40 }}>
          <strong>
            <span style={{ fontSize: 20 }}>
              {customerInfo?.firstName}{' '}{customerInfo?.lastName}
            </span>
          </strong>
        </CCardHeader>
        <CCardBody>
          <CCardTitle>
            <CRow>
              <CCol lg={2} xs={12}>
                <div className={"form-avatar"}>
                  <img
                    alt={""}                 
                    //src={`data:image/png;base64,${customerInfo?.photo}`}
                   src={customerInfo?.photo ? `data:image/png;base64,${customerInfo?.photo}` : logo}
                   onError={(e) => {
                    e.currentTarget.src = require("../../../assets/images/merit_logo.png");
                  }}
          
                  />
                </div>
              </CCol>
              <CCol lg={10} xs={12} style={{ marginTop: 20 }}>
                <CTable>
                  <CTableHead>
                    <CTableRow>
                      <CTableHeaderCell scope="col">
                        <span style={{ fontSize: 16 }}>Customer ID</span>
                      </CTableHeaderCell>
                      <CTableHeaderCell scope="col">
                        <span style={{ fontSize: 16 }}>Nationality</span>
                      </CTableHeaderCell>
                      <CTableHeaderCell scope="col">
                        <span style={{ fontSize: 16 }}>Identity No</span>
                      </CTableHeaderCell>
                    </CTableRow>
                  </CTableHead>
                  <CTableBody>
                    <CTableRow>
                      <CTableDataCell>
                        <span style={{ fontSize: 14 }}>{customerInfo?.customerId ? customerInfo?.customerId : 0}</span>
                      </CTableDataCell>
                      <CTableDataCell>
                        <span style={{ fontSize: 14 }}>{customerInfo?.nationality ? customerInfo?.nationality : "-"}</span>
                      </CTableDataCell>
                      <CTableDataCell>
                        <span style={{ fontSize: 14 }}>{customerInfo?.identityNo ? customerInfo?.identityNo : 0}</span>
                      </CTableDataCell>
                    </CTableRow>
                  </CTableBody>
                </CTable>
              </CCol>
            </CRow>

            <br />
          </CCardTitle>
          <CCardText>
            <CTable bordered>
              <CTableHead>
                <CTableRow color="light">
                  <CTableHeaderCell scope="col">{""}</CTableHeaderCell>
                  <CTableHeaderCell scope="col">Drop</CTableHeaderCell>
                  <CTableHeaderCell scope="col">
                    Cash Out
                  </CTableHeaderCell>
                  <CTableHeaderCell scope="col">Winloss</CTableHeaderCell>
                </CTableRow>
              </CTableHead>
              <CTableBody>
                <CTableRow>
                  <CTableHeaderCell scope="row">LG</CTableHeaderCell>
                  <CTableDataCell>{totalLgDrop ? formattedData(totalLgDrop.toFixed(0).toString()) : 0}</CTableDataCell>
                  <CTableDataCell>{totalLgOut ? formattedData(totalLgOut.toFixed(0).toString()) : 0}</CTableDataCell>
                  <CTableDataCell>{totalLgWinloss ? formattedData(totalLgWinloss.toFixed(0).toString()) : 0}</CTableDataCell>
                </CTableRow>
                <CTableRow>
                  <CTableHeaderCell scope="row">Slot</CTableHeaderCell>
                  <CTableDataCell>{totalSmDrop ? formattedData(totalSmDrop.toFixed(0).toString()) : 0}</CTableDataCell>
                  <CTableDataCell>{totalSmOut ? formattedData(totalSmOut.toFixed(0).toString()) : 0}</CTableDataCell>
                  <CTableDataCell>{totalSmWinloss ? formattedData(totalSmWinloss.toFixed(0).toString()) : 0}</CTableDataCell>
                </CTableRow>
                <CTableRow>
                  <CTableHeaderCell scope="row">Total</CTableHeaderCell>
                  <CTableDataCell>{totalDrop ? formattedData(totalDrop.toFixed(0).toString()) : 0}</CTableDataCell>
                  <CTableDataCell>{totalOut ? formattedData(totalOut.toFixed(0).toString()) : 0}</CTableDataCell>
                  <CTableDataCell>{totalWinloss ? formattedData(totalWinloss.toFixed(0).toString()) : 0}</CTableDataCell>
                </CTableRow>
              </CTableBody>
            </CTable>
          </CCardText>
          <br />
          <CRow>
            <CCol xs={12} sm={6} lg={3}>
              <CWidgetStatsF
                className="mb-3"
                color="info"
                icon={<CIcon icon={cilChartPie} height={24} />}
                title="Chip Discount"
                value={
                  <span>
                    $ {totalChipDiscount ? totalChipDiscount && formattedData(totalChipDiscount.toFixed(0).toString()) : 0}
                  </span>
                }
              />
            </CCol>
            <CCol xs={12} sm={6} lg={3}>
              <CWidgetStatsF
                className="mb-3"
                color="primary"
                icon={<CIcon icon={cilChartPie} height={24} />}
                title="Cash Discount"
                value={
                  <span>
                    $ {totalCashDiscount ? totalCashDiscount && formattedData(totalCashDiscount.toFixed(0).toString()) : 0}
                  </span>
                }
              />
            </CCol>
            <CCol xs={12} sm={6} lg={3}>
              <CWidgetStatsF
                className="mb-3"
                color="warning"
                icon={<CIcon icon={cilChartPie} height={24} />}
                title="Total Discount"
                value={
                  <span>
                    $ {totalDiscount ? totalDiscount && formattedData(totalDiscount.toFixed(0).toString()) : 0}
                  </span>
                }
              />
            </CCol>
            <CCol xs={12} sm={6} lg={3}>
              <CWidgetStatsF
                className="mb-3"
                color="danger"
                icon={<CIcon icon={cilChartPie} height={24} />}
                title="Net Winloss"
                value={
                  <span>
                    $ {totalNetWinloss ? totalNetWinloss && formattedData(totalNetWinloss.toFixed(0).toString()) : 0}
                  </span>}

              />
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
      <br />
      <TabPanel
        //height={260}
        // dataSource={customers}
        selectedIndex={selectedIndex}
        //onOptionChanged={onTabSelectionChanged}
        // loop={loop}
        //itemTitleRender={renderListItem}
        //itemComponent={CompanyItem}
        onSelectionChanged={onTabSelectionChanged}
        animationEnabled={animationEnabled}
        swipeEnabled={true}
        onItemClick={(e) => console.log('Tab', e)}
      >
        {/* Winloss Tab */}
        <Item title="Winloss">
          <DataGrid
            id="grid"
            dataSource={customerWinlossYearly}
            //keyExpr="index"
            columnAutoWidth={true}
            allowColumnReordering={false}
            showBorders={true}
          >
            <GroupPanel visible={false} />
            <Grouping autoExpandAll={false} />
            <Paging defaultPageSize={10} />
            <Column dataField="casinoYear" caption="" groupIndex={0}  
            sortOrder="desc"  // Başlangıç sıralama düzenini azalan olarak ayarlar
            sortIndex={0}    // Başlangıç sıralama indeksini 0'a ayarlar (ilk sütun)
            calculateSortValue={(data) => -parseInt(data.creditYear)} // Özel sıralama işlevi
            />
            <Column dataField="casinoMonth" caption="" groupIndex={1} />
            <Column dataField="casinoDay" caption="" />
            <Column dataField="drop" caption="Drop" format="fixedPoint" />
            <Column
              dataField="winLoss"
              caption="Result"
              format="fixedPoint"
            />
            <Column
              dataField="credit"
              caption="Credit"
              format="fixedPoint"
            />
            <Column
              dataField="discountCash"
              caption="Cash Dis."
              format="fixedPoint"
            />
            <Column
              dataField="discountGame"
              caption="Chip Dis."
              format="fixedPoint"
            />
            <Column
              dataField="netWinloss"
              caption="Net Winloss"
              format="fixedPoint"
            />
            <Column
              dataField="missingChip"
              caption="Missing Chip"
              format="fixedPoint"
            />
            <Column
              dataField="marker"
              caption="Marker"
              format="fixedPoint"
            />
            <Summary>
              <GroupItem
                column="drop"
                summaryType="sum"
                displayFormat="{0}"
                valueFormat="fixedPoint"
                alignByColumn={true}
              />
              <GroupItem
                column="winLoss"
                summaryType="sum"
                displayFormat="{0}"
                valueFormat="fixedPoint"
                alignByColumn={true}
              />
              <GroupItem
                column="credit"
                summaryType="sum"
                displayFormat="{0}"
                valueFormat="fixedPoint"
                alignByColumn={true}
              />
              <GroupItem
                column="discountCash"
                summaryType="sum"
                displayFormat="{0}"
                valueFormat="fixedPoint"
                alignByColumn={true}
              />
              <GroupItem
                column="discountGame"
                summaryType="sum"
                displayFormat="{0}"
                valueFormat="fixedPoint"
                alignByColumn={true}
              />
              <GroupItem
                column="netWinloss"
                summaryType="sum"
                displayFormat="{0}"
                valueFormat="fixedPoint"
                alignByColumn={true}
              />
              <GroupItem
                column="missingChip"
                summaryType="sum"
                displayFormat="{0}"
                valueFormat="fixedPoint"
                alignByColumn={true}
              />
              <GroupItem
                column="marker"
                summaryType="sum"
                displayFormat="{0}"
                valueFormat="fixedPoint"
                alignByColumn={true}
              />
            </Summary>
          </DataGrid>
        </Item>
        {/* Junket Tab */}
        <Item title="Junket">
          <DataGrid
            id="grid"
            dataSource={customerJunketWinloss}
            // keyExpr="ID"
            columnAutoWidth={true}
            allowColumnReordering={false}
            showBorders={true}
          >
            <Paging defaultPageSize={10} />
            <Column
              dataField="casino"
              caption="Casino"
              dataType="string"
            />
            <Column
              dataField="reportName"
              caption="Report Name"
              dataType="string"
            />
            <Column
              dataField="subName"
              caption="Sub Name"
              dataType="string"
            />
            <Column
              dataField="casinoInDate"
              caption="Casino In Date"
              dataType="date"
            />
            <Column dataField="casinoDrop" caption="Drop" format="fixedPoint" />
            <Column
              dataField="casinoWinloss"
              caption="Winloss"
              format="fixedPoint"
            />
            <Column
              dataField="credit"
              caption="Credit"
              format="fixedPoint"
            />
          </DataGrid>
        </Item>
        {/* Reservation Tab */}
        <Item title="Reservation">
          <DataGrid
            id="grid"
            dataSource={customerReservations}
            // keyExpr="ID"
            columnAutoWidth={true}
            allowColumnReordering={false}
            showBorders={true}
          >
            <Paging defaultPageSize={10} />
            <Column
              dataField="casino"
              caption="Casino"
              dataType="string"
            />
            <Column
              dataField="reportName"
              caption="Report Name"
              dataType="string"
            />
            <Column
              dataField="subName"
              caption="Sub Name"
              dataType="string"
            />
            <Column
              dataField="casinoInDate"
              caption="Casino In Date"
              dataType="date"
            />
            <Column
              dataField="casinoOutDate"
              caption="Casino Out Date"
              dataType="date"
            />
          </DataGrid>
        </Item>
        {/* Discount Tab */}
        <Item title="Discount">
          <DataGrid
            id="grid"
            dataSource={customerDiscount}
            keyExpr="actionId"
            columnAutoWidth={true}
            allowColumnReordering={false}
            showBorders={true}
          >
            <Paging defaultPageSize={10} />
            <Column
              dataField="casino"
              caption="Casino"
            />
            <Column
              dataField="casinoDate"
              caption="Casino Date"
              dataType="date"
            />
            <Column dataField="discountGame" caption="Game" format="fixedPoint" />
            <Column dataField="discountCash" caption="Cash" format="fixedPoint" />
            <Column
              dataField="winloss"
              caption="Winloss"
              format="fixedPoint"
            />
            <Column
              dataField="lgDiscRate"
              caption="Lg Rate"
              format="fixedPoint"
            />
            <Column
              dataField="smDiscRate"
              caption="Sm Rate"
              format="fixedPoint"
            />
            <Column
              dataField="discNotes"
              caption="Description"
              format="fixedPoint"
            />
          </DataGrid>
        </Item>
        {/* Credit Tab */}
        <Item title="Credit">
          <DataGrid
            id="grid"
            dataSource={customerCreditList}
            keyExpr="creditID"
            columnAutoWidth={true}
            allowColumnReordering={false}
            showBorders={true}
          >
            <Paging defaultPageSize={10} />
            <Column dataField="creditDay" caption="Date" format="fixedPoint" />
            <Column
              dataField="casinoName"
              caption="Casino"
              format="fixedPoint"
            />
            <Column
              dataField="currency"
              caption="Currency"
              format="fixedPoint"
            />
            <Column
              dataField="mainCredit"
              caption="Credit"
              format="fixedPoint"
            />
            <Column
              dataField="mainEncash"
              caption="Return"
              format="fixedPoint"
            />
            <Column
              dataField="mainExpensed"
              caption="Expensed"
              format="fixedPoint"
            />
            <Column
              dataField="discount"
              caption="Discount"
              format="fixedPoint"
            />
            <Column
              dataField="balance"
              caption="Balance"
              format="fixedPoint"
            />
            <Summary>
              <GroupItem
                column="mainCredit"
                summaryType="sum"
                displayFormat="{0}"
                valueFormat="fixedPoint"
                alignByColumn={true}
              />
              <GroupItem
                column="mainEncash"
                summaryType="sum"
                displayFormat="{0}"
                valueFormat="fixedPoint"
                alignByColumn={true}
              />
              <GroupItem
                column="balance"
                summaryType="sum"
                displayFormat="{0}"
                valueFormat="fixedPoint"
                alignByColumn={true}
              />
            </Summary>
          </DataGrid>
        </Item>
      </TabPanel>
    </div>
  );
};

export default CustomerDetail;