export const navigation = [
  {
    text: 'Home',
    path: '/home',
    icon: 'home'
  },
  {
    text: 'Status',
    path: '/status',
    icon: 'chart',
  }, 
  {
    text: 'Customers',
    path: '/customer',
    icon: 'user'
  },
  {
    text: 'LiveGame',
    path: '/livegame',
    icon: 'money'
  }, 
  {
    text: 'Credits',
    path: '/credit',
    icon: 'detailslayout'
  }
  ];
