import React, { useState, useRef, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Form, {
  Item,
  Label,
  ButtonItem,
  ButtonOptions,
  RequiredRule,
} from 'devextreme-react/form';
import LoadIndicator from 'devextreme-react/load-indicator';
import notify from 'devextreme/ui/notify';
import { useAuth } from '../../contexts/auth';

import './AuthForm.scss';
import { useGlobal } from '../../contexts/global';

export default function AuthForm() {
  const navigate = useNavigate();
  const { authVerifyKey, name, authUser } = useAuth();
  const [loading, setLoading] = useState(false);
  const formData = useRef({ verificationCode: '' });

  const onSubmit = useCallback(async (e: any) => {
    e.preventDefault();
    const { verificationCode } = formData.current;
    if (name != undefined) {
      setLoading(true);
      const result = await authVerifyKey(name, verificationCode);
      if (!result.isOk) {
        notify('Invalid verification code!', 'error', 2000);
        navigate("/auth");
        setLoading(false);
      } else {
        navigate("/home");
        setLoading(false);
      }
    } else {
      console.log("Name undifined");
    }
  }, [authVerifyKey]);

  return (
    <form className={'login-form'} onSubmit={onSubmit}>
      <Form formData={formData.current} disabled={loading}>
        <Item
          dataField={'verificationCode'}
          editorType={'dxTextBox'}
          editorOptions={passwordEditorOptions}
        >
          <RequiredRule message="Verification Code is required" />
          <Label visible={false} />
        </Item>
        <ButtonItem>
          <ButtonOptions
            width={'100%'}
            type={'default'}
            useSubmitBehavior={true}
          >
            <span className="dx-button-text">
              {
                loading
                  ? <LoadIndicator width={'24px'} height={'24px'} visible={true} />
                  : 'Login'
              }
            </span>
          </ButtonOptions>
        </ButtonItem>

      </Form>
      <span className="dx-text" style={{ color: "red",opacity:0.5 }}>
        A verification code has been sent to your phone.
      </span>
    </form>
  );
}

const passwordEditorOptions = { stylingMode: 'filled', placeholder: 'Authentication Key', mode: 'password' };

