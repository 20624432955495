import { HomePage, CreditPage, CustomersPage, LiveGamePage, StatusPage, GameDetail, TableDetail, CustomerDetail } from './pages';
import { withNavigationWatcher } from './contexts/navigation';

const routes = [
    {
        path: '/home',
        element: HomePage
    },
    {
        path: '/status',
        element: StatusPage
    },
    {
        path: '/customer',
        element: CustomersPage
    },
    {
        path: '/customer/:id',
        element: CustomerDetail
    },
    {
        path: '/livegame',
        element: LiveGamePage
    },
    {
        path: '/game',
        element: GameDetail
    },
    {
        path: '/game/table',
        element: TableDetail
    },
    {
        path: '/credit',
        element: CreditPage
    },
];

export default routes.map(route => {
    return {
        ...route,
        element: withNavigationWatcher(route.element, route.path)
    };
});
