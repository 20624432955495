import React, { useState } from 'react';
import "./styles.css";
import "./customer.scss";
import { useNavigate } from 'react-router-dom';
import {
  CTable,
  CTableDataCell,
  CTableRow,
  CTableBody,
  CButton,
  CFormInput,
  CInputGroup,
  CImage
} from "@coreui/react";
import CIcon from '@coreui/icons-react';
import {
  cilSearch,
} from "@coreui/icons";
import logo from '../../assets/images/merit_logo.png';
import type { CustomerSearch } from '../../types.js';
import { Config } from '../../config/config';
import { useRef } from 'react';



export default function Customer() {
  const [filteredData, setFilteredData] = useState<CustomerSearch[]>([]);
  const [wordEntered, setWordEntered] = useState<any>("");
  const [showWarning, setShowWarning] = useState(false);
  const windowSize = useRef([window.innerWidth, window.innerHeight]);
  const navigate = useNavigate();
  const token = localStorage.getItem('accessToken');

  async function customerSearch(input: string): Promise<CustomerSearch[]> {
    const headers: Headers = new Headers()
    headers.set('Content-Type', 'application/json')
    headers.set('Accept', 'application/json')
    headers.set('Authorization', `Bearer ${token}`)

    const isNumericInput = !isNaN(Number(input));
    const params: Record<string, string> = { customerId: '', searchFirstName: '' };

    if (isNumericInput) {
      params['customerId'] = input;
      params['searchFirstName'] = '';
    } else {
      params['searchFirstName'] = input;
      params['customerId'] = '0';
    }

    const request: RequestInfo = new Request(`${Config.ApiBaseUrl}/customer/search?count=20&page=1&customerId=${params['customerId']}&searchFirstName=${params['searchFirstName']}`, {
      method: 'GET',
      headers: headers,
    })

    if (!input.trim()) {
      setShowWarning(true);
      return [];
    }

    const res = await fetch(request);
    const response = await res.json();
    setFilteredData(response);
    setShowWarning(false);
    return response as CustomerSearch[];
  }

  return (
    <React.Fragment>
      <div className="clearfix" style={{ padding: '50px 50px 20px 50px' }}>
        <CImage align="center" rounded src={logo} width={150} height={150} />
      </div>
      <CInputGroup className="flex-nowrap">
        <CFormInput
          placeholder="Enter a Customer ID or Name"
          aria-label="search"
          aria-describedby="addon-wrapping"
          value={wordEntered}
          onChange={(event) => {
            const searchWord = event.target.value;
            setWordEntered(searchWord);
            setShowWarning(false); // İçine yazı veya ID yazılmaya başlandığında uyarıyı gizle
          }}
        />
        <CButton
          color="dark"
          variant="outline"
          onClick={() => {
            customerSearch(wordEntered);
          }}
        >
          <CIcon icon={cilSearch} size="sm" />
        </CButton>
      </CInputGroup>
      {showWarning && (
        <div className="warningMessage" style={{ color: 'red', marginTop: '10px', marginLeft: '10px' }}>
          Please enter a search term.
        </div>
      )}
      <div>
        {filteredData.length !== 0 && (
          <div className="dataResult" style={{ height: 400, width: "95%", marginLeft: '50px' }}>
            {filteredData.slice(0, 15).map((data, key) => {
              return (
                <CTable className="dataItem" borderless key={key}>
                  <CTableBody>
                    <CTableRow
                      onClick={() => {
                        navigate(`/customer/${data.customerId}/`, { state: { data } });
                        const id = data.customerId;
                        localStorage.setItem("searchCustomerId", id.toString());
                        setWordEntered("");
                        setFilteredData([]);
                      }}
                    >
                      <CTableDataCell>
                        {data.customerId}
                      </CTableDataCell>
                      <CTableDataCell>
                        {data.fullName}
                      </CTableDataCell>
                    </CTableRow>
                  </CTableBody>
                </CTable>
              );
            })}
          </div>
        )}
      </div>
    </React.Fragment>
  );
}