import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import DataGrid, {
  Column,
  Selection,
  Paging
} from "devextreme-react/data-grid";

import { CWidgetStatsA } from "@coreui/react";
import {
  CRow,
  CCol,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import {
  cilCaretLeft,
} from "@coreui/icons";
import { useScreenSize } from '../../../utils/media-query';
import { useNavigate } from 'react-router-dom';
import type { TableTypes } from '../../../types.js';
import { Config } from '../../../config/config';
import { useGlobal } from "../../../contexts/global";
import { LoadIndicator } from 'devextreme-react/load-indicator';
import dayjs from 'dayjs'
import { useAuth } from '../../../contexts/auth';

export default function GameDetail({ }: React.PropsWithChildren) {
  const { currentCasino, dateRange } = useGlobal();
  const { accessToken } = useAuth();
  const [tableTypes, setTableTypes] = useState<TableTypes[]>([]);
  const [totalDrop, setTotalDrop] = useState<number>(0);
  const [totalResult, setTotalResult] = useState<number>(0);

  const [loadIndicatorVisible, setloadIndicatorVisible] = useState<boolean>(false);

  const navigate = useNavigate();
  const location = useLocation();
  const data = location.state.data;
  const gameTypeId = data.gameTypeId;

  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get('gameTypeId');

  const { isXSmall } = useScreenSize();

  const onSelectionChanged = ({ selectedRowsData }: { selectedRowsData: any[] }) => {
    const data = selectedRowsData[0];
    navigate(`/game/table?gameTypeId=${id}&tableId=${data.tableId}`, { state: { data } })
  }

  const back = () => {
    navigate(-1);
  }

  //Get Game Types
  async function getGameTypes(casinoId: number): Promise<TableTypes> {
    setloadIndicatorVisible(true);
    const headers: Headers = new Headers()
    headers.set('Content-Type', 'application/json')
    headers.set('Accept', 'application/json')
    headers.set('Authorization', `Bearer ${accessToken}`)

    const request: RequestInfo = new Request(`${Config.ApiBaseUrl}/game/gettablestotal?casinoId=${casinoId}&gameTypeId=${gameTypeId}&reportStartDate=${dayjs(dateRange[0]).format('YYYY-MM-DD')}&reportEndDate=${dayjs(dateRange[1]).format('YYYY-MM-DD')}`, {
      method: 'GET',
      headers: headers
    })

    const res = await fetch(request);
    const response = await res.json();
    setTableTypes(response);
    setTotalDrop(response[0]?.tableDropTotal);
    setTotalResult(response[0]?.resultTotal);
    setloadIndicatorVisible(false);
    return response as TableTypes;
  }

  useEffect(() => {
    if (currentCasino != null && currentCasino.casinoId !== 0) {
      //console.log("DateRange",dateRange);
      getGameTypes(currentCasino.casinoId)
    }
  }, [currentCasino, dateRange])


  function formattedData(data: string) {
    const format = data?.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    return format;
  }

  return (
    <React.Fragment>
      {(loadIndicatorVisible === false) ?
        (
          <div>
            <br />
            <CRow>
              {/* <CCol xs={2}><CIcon icon={cilCaretLeft} size="lg" id="clearBtn" onClick={back} /></CCol>
              <CCol xs={10}><h6>Game Type: {data.gameType}</h6></CCol> */}
              <div className='col-lg-1 col-1'><CIcon icon={cilCaretLeft} size="lg" id="clearBtn" onClick={back} /></div>
              <div className='col-lg-11 col-11'><h6>Game Type: {data.gameType}</h6></div>
            </CRow>
            <br />
            <div>
              <div>
                <CRow>
                  <CCol xs={6} sm={6} lg={6}>
                    <CWidgetStatsA
                      className="mb-4 pb-3"
                      color="primary"
                      value={
                        <span style={{ fontSize: 16}}>
                          $ {formattedData(totalDrop?.toFixed(0).toString())}
                        </span>
                      }
                      title="Drop"
                    />
                  </CCol>
                  <CCol xs={6} sm={6} lg={6}>
                    <CWidgetStatsA
                      className="mb-4 pb-3"
                      color="info"
                      value={
                        <span style={{ fontSize: 16}}>
                          $ {formattedData(totalResult?.toFixed(0).toString())}
                        </span>
                      }
                      title="Result"
                    />
                  </CCol>
                </CRow>
              </div>
              <br />
              <div>
                <DataGrid
                  id="grid"
                  dataSource={tableTypes}
                  keyExpr="tableId"
                  allowColumnReordering={false}
                  showBorders={true}
                  onSelectionChanged={onSelectionChanged}
                  hoverStateEnabled={true}
                  columnHidingEnabled={isXSmall ? true : false}
                  columnAutoWidth={isXSmall ? true : false}
                  allowColumnResizing={isXSmall ? false : true}
                  height="100%"
                  scrolling={{ mode: 'virtual', showScrollbar: 'never' }}
                  onRowPrepared={(e) => {
                    if (e.rowType === 'data') {
                      e.rowElement.style.cursor = 'pointer';
                    }
                  }}                
                >
                  <Paging defaultPageSize={8} />
                  <Selection mode="single" />
                  <Column
                    dataField="tableId"
                    caption="Table ID"
                  />
                  <Column dataField="tableName" caption="Table Name" format="fixedPoint" />
                  <Column dataField="tableDrop" caption="Table Drop" format="fixedPoint" />
                  <Column dataField="result" caption="Result" format="fixedPoint" />
                </DataGrid>
              </div>
            </div>
          </div>
        ) : (
          <div style={{ textAlign: 'center', marginTop: 40 }}>
            <LoadIndicator id="medium-indicator" height={40} width={40} />
          </div>
        )}
    </React.Fragment>
  );
}

