import React, { useState, useEffect } from 'react';
import DataGrid, {
  Column,
  Scrolling,
  Summary,
  TotalItem,
  LoadPanel,
  Sorting,
  Paging
} from "devextreme-react/data-grid";
import { ButtonGroup } from 'devextreme-react/button-group';
import Button from 'devextreme-react/button';
import { useGlobal } from "../../contexts/global";
import type { CasinoStatus } from '../../types.js';
import { Config } from '../../config/config';
import { CModal, CModalHeader, CModalTitle, CModalBody } from '@coreui/react';
import SelectBox from 'devextreme-react/select-box';
import { LoadIndicator } from 'devextreme-react/load-indicator';
import { useAuth } from '../../contexts/auth';
import { useScreenSize } from '../../utils/media-query';
import { Height } from 'devextreme-react/chart';

export default function Status() {
  const { currentCasino } = useGlobal();
  const { accessToken } = useAuth();

  const [casinoStatus, setCasinoStatus] = useState<CasinoStatus[]>([]);
  const [dateFilter, setDateFilter] = useState(1);
  const [dateModalFilter, setDateModalFilter] = useState(1);
  const [visibleSm, setVisibleSm] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState(1);
  const [selectedYear, setSelectedYear] = useState(2024);
  const [loadIndicatorVisible, setloadIndicatorVisible] = useState<boolean>(false);
  const { isXSmall, isSmall, isMedium, isLarge } = useScreenSize();
  

  //DateFilter
  const dateFilterData = [
    {
      hint: 'Daily',
      text: 'Daily',
    },
    {
      hint: 'Monthly',
      text: 'Monthly',
    },
    {
      hint: 'Yearly',
      text: 'Yearly',
    },
  ];

  const modalData = [
    {
      hint: 'Months',
      text: 'Months',
    },
    {
      hint: 'Years',
      text: 'Years',
    },
  ];

  function dateFilterClick(e: any) {
    const title = e.itemData.hint;
    if (title === 'Daily') {
      setDateFilter(1)
    } else if (title === 'Monthly') {
      setDateFilter(2)
    } else if (title === 'Yearly') {
      setDateFilter(3)
    } else {
      setDateFilter(4)
    }
    //notify({ message: `The "${e.itemData.hint}" button was clicked`, width: 320 }, 'success', 1000);
  }

  function dateModalClick(e: any) {
    const title = e.itemData.hint;
    if (title === 'Months') {
      setDateModalFilter(1)
    } else if (title === 'Years') {
      setDateModalFilter(2)
    }
  }

  function formatDateToYYYYMMDD(date: Date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
  }


  function parseDateStringToDate(dateString: string): Date {
    return new Date(dateString);
  }

  //Get CasinoStatus Daily
  async function getCasinoStatusDaily(casinoId: number): Promise<CasinoStatus> {

    let currentDate = currentCasino?.activeDate;
    console.log("CurrentDate", currentDate, typeof(currentDate));
    if (typeof currentDate === 'string') {
      currentDate = parseDateStringToDate(currentDate);
    }
  

    if (currentDate == undefined || currentDate == new Date(1900, 1, 1) || currentDate.toString() == "0001-01-01T00:00:00") {
      currentDate = new Date();
    }

    const firstDay = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    const lastDay = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);

    const first = formatDateToYYYYMMDD(firstDay);
    const end = formatDateToYYYYMMDD(lastDay);
    setloadIndicatorVisible(true);
    const headers: Headers = new Headers()
    headers.set('Content-Type', 'application/json')
    headers.set('Accept', 'application/json')
    headers.set('Authorization', `Bearer ${accessToken}`)
    const request: RequestInfo = new Request(`${Config.ApiBaseUrl}` + '/casinostatus/getdaily?casinoId=' + casinoId + '&start=' + first + '&end=' + end, {
      method: 'GET',
      headers: headers
    })

    const res = await fetch(request);
    const response = await res.json();
    // Veriyi tersine çevir
    const reversedData = response.slice().reverse();
    setCasinoStatus(reversedData);
    setloadIndicatorVisible(false);
    return response as CasinoStatus;
  }

  //Get CasinoStatus Monthly Current Year
  async function getCasinoStatusMonthly(casinoId: number): Promise<CasinoStatus> {
    setloadIndicatorVisible(true);
    const currentYear = new Date().getFullYear();
    const headers: Headers = new Headers()
    headers.set('Content-Type', 'application/json')
    headers.set('Accept', 'application/json')
    headers.set('Authorization', `Bearer ${accessToken}`)

    const request: RequestInfo = new Request(`${Config.ApiBaseUrl}/casinostatus/getmonthly?casinoId=${casinoId}&year=${currentYear}`, {
      method: 'GET',
      headers: headers
    })

    const res = await fetch(request);
    const response = await res.json();
    setCasinoStatus(response);
    setloadIndicatorVisible(false);
    return response as CasinoStatus;
  }

  //Get CasinoStatus Yearly
  async function getCasinoStatusYearly(casinoId: number): Promise<CasinoStatus> {
    setloadIndicatorVisible(true);
    const headers: Headers = new Headers()
    headers.set('Content-Type', 'application/json')
    headers.set('Accept', 'application/json')
    headers.set('Authorization', `Bearer ${accessToken}`)

    const request: RequestInfo = new Request(`${Config.ApiBaseUrl}/casinostatus/getyearly?casinoId=${casinoId}`, {
      method: 'GET',
      headers: headers
    })

    const res = await fetch(request);
    const response = await res.json();
    // Veriyi tersine çevir
    const reversedData = response.slice().reverse();
    setCasinoStatus(reversedData);

    setloadIndicatorVisible(false);
    return response as CasinoStatus;
  }

  //Get CasinoStatus 2023-  Year Modal
  async function getCasinoStatusModalYears(casinoId: number, year: number): Promise<CasinoStatus> {
    setloadIndicatorVisible(true);
    const headers: Headers = new Headers()
    headers.set('Content-Type', 'application/json')
    headers.set('Accept', 'application/json')
    headers.set('Authorization', `Bearer ${accessToken}`)

    const request: RequestInfo = new Request(`${Config.ApiBaseUrl}/casinostatus/getmonthly?casinoId=${casinoId}&year=${year}`, {
      method: 'GET',
      headers: headers
    })

    const res = await fetch(request);
    const response = await res.json();
    setCasinoStatus(response);
    setloadIndicatorVisible(false);
    return response as CasinoStatus;
  }

  // Get Casino Status 2023  - Select Month Year
  async function getCasinoStatusDailybyMonth(casinoId: number, year: number, month: number): Promise<CasinoStatus> {
    setloadIndicatorVisible(true);
    const headers: Headers = new Headers()
    headers.set('Content-Type', 'application/json')
    headers.set('Accept', 'application/json')
    headers.set('Authorization', `Bearer ${accessToken}`)

    const request: RequestInfo = new Request(`${Config.ApiBaseUrl}/casinostatus/getdailybymonth?casinoId=${casinoId}&year=${year}&month=${month}`, {
      method: 'GET',
      headers: headers
    })

    const res = await fetch(request);
    const response = await res.json();
    // Veriyi tersine çevir
    const reversedData = response.slice().reverse();
    setCasinoStatus(reversedData);
    setloadIndicatorVisible(false);
    return response as CasinoStatus;
  }

  useEffect(() => {
    if (currentCasino == null) return;
    if (dateFilter === 1) {
      if (currentCasino.casinoId !== 0 && currentCasino?.activeDate) {
        getCasinoStatusDaily(currentCasino.casinoId)
      }
    } else if (dateFilter === 2) {
      getCasinoStatusMonthly(currentCasino.casinoId)
    } else if (dateFilter === 3) {
      getCasinoStatusYearly(currentCasino.casinoId)
    }
  }, [dateFilter, currentCasino])


  const setModalDate = () => {
    if (currentCasino == null) return;

    setDateFilter(4);
    if (dateModalFilter === 1) {
      getCasinoStatusDailybyMonth(currentCasino.casinoId, selectedYear, selectedMonth)
    } else if (dateModalFilter === 2) {
      getCasinoStatusModalYears(currentCasino.casinoId, selectedYear);
    }
    setVisibleSm(false);
  }

  // const simpleProductLabel = { 'aria-label': 'Simple Product' };
  const months = [
    { id: 1, name: "January" },
    { id: 2, name: "February" },
    { id: 3, name: "March" },
    { id: 4, name: "April" },
    { id: 5, name: "May" },
    { id: 6, name: "June" },
    { id: 7, name: "July" },
    { id: 8, name: "August" },
    { id: 9, name: "September" },
    { id: 10, name: "October" },
    { id: 11, name: "November" },
    { id: 12, name: "December" },
  ];

  const years = [
    { id: 1, name: 2024 },
    { id: 2, name: 2023 },
    { id: 3, name: 2022 },
    { id: 4, name: 2021 },
    { id: 5, name: 2020 },
    { id: 6, name: 2019 },
    { id: 7, name: 2018 },
    { id: 8, name: 2017 },
    { id: 9, name: 2016 },
    { id: 10, name: 2015 },
    { id: 11, name: 2014 },
    { id: 12, name: 2013 },
    { id: 13, name: 2012 },
    { id: 14, name: 2011 },
    { id: 15, name: 2010 },
    { id: 16, name: 2009 },
    { id: 17, name: 2008 },
  ];

  function onMonthValueChanged(e: any) {
    const month = e.value;
    setSelectedMonth(month);
  }

  function onYearValueChanged(e: any) {
    const year = e.value;
    setSelectedYear(year);
  }

  return (
    <React.Fragment>
      <div style={{ marginBottom: 60 }}>
        <br />
        <div style={{ width: '100%', height: 50 }}>
          <ButtonGroup
            items={dateFilterData}
            keyExpr="style"
            stylingMode="outlined"
            selectionMode="none"
            onItemClick={dateFilterClick}
          />
          <Button
            width={70}
            style={{ marginLeft: 5, marginBottom: 1 }}
            text={selectedYear.toString()}
            type="normal"
            stylingMode="outlined"
            onClick={() => { setVisibleSm(!visibleSm); }}
          />

        </div>
        {(loadIndicatorVisible === false) ?
          (
            <DataGrid
              id="grid"
              dataSource={casinoStatus}
              keyExpr="casinoId"
              columnAutoWidth={true}
              allowColumnReordering={false}
              showBorders={true}

            >
              <Scrolling mode="standard" showScrollbar='onHover' useNative={true} />

              <Paging defaultPageSize={10} />
              <LoadPanel enabled={false} />
              {/* Daily Filter Column */}
              {dateFilter === 1 ? (
                <Column dataField="casinoDate" caption="Date" dataType="datetime" format="dd/MM/yyyy" />
              ) : dateFilter === 2 ? (<Column dataField="month" caption="Month" width={100} />) : dateFilter === 3 ?
                (<Column dataField="year" caption="Year" width={100} />) : null}


              {dateFilter === 4 && dateModalFilter === 1 ?
                (<Column dataField="casinoDate" caption="Date" dataType="datetime" format="dd/MM/yyyy" />) : dateFilter === 4 && dateModalFilter === 2 ?
                  (<Column dataField="month" caption="Month" width={100} />) : null}

              {/* Live Game */}
              <Column caption="LiveGame">
                <Column
                  dataField="lgD"
                  caption="Drop"
                  format="fixedPoint"
                />
                <Column
                  dataField="lgR"
                  caption="Result"
                  format="fixedPoint"
                />
                <Column
                  dataField="lgHold"
                  caption="Hold"
                  format="fixedPoint"
                />
                <Column
                  dataField="lgDiscount"
                  caption="Discount"
                  format="fixedPoint"
                />
                <Column
                  dataField="lgDiscPer"
                  caption="Disc. Per."
                  format="fixedPoint"
                />
                <Column
                  dataField="lgNetR"
                  caption="Net Result"
                  format="fixedPoint"
                />
              </Column>
              {/* Slot Machines */}
              <Column caption="Slot Machines">
                <Column
                  dataField="smD"
                  caption="Drop"
                  format="fixedPoint"
                //sortOrder="desc"
                />
                <Column
                  dataField="smR"
                  caption="Result"
                  format="fixedPoint"
                //width={95}
                />
                <Column
                  dataField="smHold"
                  caption="Hold"
                  format="fixedPoint"
                //width={80}
                />
                <Column
                  dataField="smDiscount"
                  caption="Discount"
                  format="fixedPoint"
                //width={85}
                />
                <Column
                  dataField="smDiscPer"
                  caption="Disc. Per."
                  format="fixedPoint"
                //width={85}
                />
                <Column
                  dataField="smNetR"
                  caption="NetResult"
                  format="fixedPoint"
                //width={85}
                />
              </Column>
              {/* Casino */}
              <Column caption="Casino">
                <Column
                  dataField="casinoD"
                  caption="Drop"
                  format="fixedPoint"
                //sortOrder="desc"
                />
                <Column
                  dataField="casinoR"
                  caption="Result"
                  format="fixedPoint"
                //width={95}
                />
                <Column
                  dataField="casinoHold"
                  caption="Hold"
                  format="fixedPoint"
                //width={80}
                />
              </Column>
              {/* Discount */}
              <Column caption="Discount">
                <Column
                  dataField="discountCash"
                  caption="Cash"
                  format="fixedPoint"
                //sortOrder="desc"
                />
                <Column
                  dataField="discountGame"
                  caption="Game"
                  format="fixedPoint"
                //width={95}
                />
                <Column
                  dataField="discount"
                  caption="Discount"
                  format="fixedPoint"
                //width={80}
                />
                <Column
                  dataField="discountPer"
                  caption="Disc. Per."
                  format="fixedPoint"
                //width={85}
                />
              </Column>
              {/* Result */}
              <Column caption="Result">
                <Column
                  dataField="casinoNetR"
                  caption="Net Result"
                  format="fixedPoint"
                //sortOrder="desc"
                />
              </Column>
              {/* Others */}
              <Column caption="Others">
                <Column
                  dataField="entrance"
                  caption="Entrance"
                  format="fixedPoint"
                //sortOrder="desc"
                />
                <Column
                  dataField="tip"
                  caption="Tip"
                  format="fixedPoint"
                //width={95}
                />
                <Column
                  dataField="credit"
                  caption="Credit"
                  format="fixedPoint"
                //width={80}
                />
                <Column
                  dataField="creditCard"
                  caption="Credit Card"
                  format="fixedPoint"
                //width={85}
                />
                <Column
                  dataField="cash"
                  caption="Cash"
                  format="fixedPoint"
                //width={80}
                />
                <Column
                  dataField="missingChip"
                  caption="Missing Chip"
                  format="fixedPoint"
                  width={90}
                />
                <Column
                  dataField="missingPlaque"
                  caption="Missing Plaque"
                  format="fixedPoint"
                  width={90}
                />
                <Column
                  dataField="texasCashGame"
                  caption="Texas Cash Game"
                  format="fixedPoint"
                  width={80}
                />
              </Column>
              {/* Total */}
              <Summary>
                <TotalItem
                  column="lgD"
                  summaryType="sum"
                  valueFormat="fixedPoint"
                  displayFormat="{0}"
                //summaryType="count"
                />
                <TotalItem
                  column="lgR"
                  summaryType="sum"
                  valueFormat="fixedPoint"
                  displayFormat="{0}"
                />
                <TotalItem
                  column="lgHold"
                  summaryType="sum"
                  valueFormat="fixedPoint"
                  displayFormat="{0}"
                //summaryType="count"
                />
                <TotalItem
                  column="lgDiscount"
                  summaryType="sum"
                  valueFormat="fixedPoint"
                  displayFormat="{0}"
                />
                <TotalItem
                  column="lgDiscPer"
                  summaryType="sum"
                  valueFormat="fixedPoint"
                  displayFormat="{0}"
                //summaryType="count"
                />
                <TotalItem
                  column="lgNetR"
                  summaryType="sum"
                  valueFormat="fixedPoint"
                  displayFormat="{0}"
                />
                <TotalItem
                  column="smD"
                  summaryType="sum"
                  valueFormat="fixedPoint"
                  displayFormat="{0}"
                //summaryType="count"
                />
                <TotalItem
                  column="smR"
                  summaryType="sum"
                  valueFormat="fixedPoint"
                  displayFormat="{0}"
                />
                <TotalItem
                  column="smHold"
                  summaryType="sum"
                  valueFormat="fixedPoint"
                  displayFormat="{0}"
                //summaryType="count"
                />
                <TotalItem
                  column="smDiscount"
                  summaryType="sum"
                  valueFormat="fixedPoint"
                  displayFormat="{0}"
                />
                <TotalItem
                  column="smDiscPer"
                  summaryType="sum"
                  valueFormat="fixedPoint"
                  displayFormat="{0}"
                //summaryType="count"
                />
                <TotalItem
                  column="smNetR"
                  summaryType="sum"
                  valueFormat="fixedPoint"
                  displayFormat="{0}"
                />
                <TotalItem
                  column="casinoD"
                  summaryType="sum"
                  valueFormat="fixedPoint"
                  displayFormat="{0}"
                //summaryType="count"
                />
                <TotalItem
                  column="casinoR"
                  summaryType="sum"
                  valueFormat="fixedPoint"
                  displayFormat="{0}"
                />
                <TotalItem
                  column="casinoHold"
                  summaryType="sum"
                  valueFormat="fixedPoint"
                  displayFormat="{0}"
                //summaryType="count"
                />
                <TotalItem
                  column="discountCash"
                  summaryType="sum"
                  valueFormat="fixedPoint"
                  displayFormat="{0}"
                />
                <TotalItem
                  column="discountGame"
                  summaryType="sum"
                  valueFormat="fixedPoint"
                  displayFormat="{0}"
                //summaryType="count"
                />
                <TotalItem
                  column="discount"
                  summaryType="sum"
                  valueFormat="fixedPoint"
                  displayFormat="{0}"
                />
                <TotalItem
                  column="discountPer"
                  summaryType="sum"
                  valueFormat="fixedPoint"
                  displayFormat="{0}"
                //summaryType="count"
                />
                <TotalItem
                  column="casinoNetR"
                  summaryType="sum"
                  valueFormat="fixedPoint"
                  displayFormat="{0}"
                //summaryType="count"
                />
                <TotalItem
                  column="entrance"
                  summaryType="sum"
                  valueFormat="fixedPoint"
                  displayFormat="{0}"
                //summaryType="count"
                />
                <TotalItem
                  column="tip"
                  summaryType="sum"
                  valueFormat="fixedPoint"
                  displayFormat="{0}"
                />
                <TotalItem
                  column="credit"
                  summaryType="sum"
                  valueFormat="fixedPoint"
                  displayFormat="{0}"
                //summaryType="count"
                />
                <TotalItem
                  column="creditCard"
                  summaryType="sum"
                  valueFormat="fixedPoint"
                  displayFormat="{0}"
                />
                <TotalItem
                  column="cash"
                  summaryType="sum"
                  valueFormat="fixedPoint"
                  displayFormat="{0}"
                //summaryType="count"
                />
                <TotalItem
                  column="missingChip"
                  summaryType="sum"
                  valueFormat="fixedPoint"
                  displayFormat="{0}"
                />
                <TotalItem
                  column="missingPlaque"
                  summaryType="sum"
                  valueFormat="fixedPoint"
                  displayFormat="{0}"
                />
                <TotalItem
                  column="texasCashGame"
                  summaryType="sum"
                  valueFormat="fixedPoint"
                  displayFormat="{0}"
                />
              </Summary>
            </DataGrid>
          ) : (
            <div style={{ textAlign: 'center', marginTop: 40 }}>
              <LoadIndicator id="medium-indicator" height={40} width={40} />
            </div>
          )}
        <>
          <CModal fullscreen="sm" visible={visibleSm} backdrop="static" onClose={() => setVisibleSm(false)}>
            <CModalHeader>
              <CModalTitle style={{fontSize:20}}>Select Month & Year</CModalTitle>
            </CModalHeader>

            <CModalBody>
              <div style={{ width: '100%', height: 50 }}>
                <ButtonGroup
                  width={"100%"}
                  items={modalData}
                  keyExpr="style"
                  stylingMode="outlined"
                  selectionMode="none"
                  onItemClick={dateModalClick}
                />
              </div>
              {dateModalFilter === 1 &&
                <>
                  <div className="dx-field">
                    <div className="dx-field-label">Month</div>
                    <div className="dx-field-value">
                      <SelectBox dataSource={months}
                        displayExpr="name"
                        valueExpr="id"
                        onValueChanged={onMonthValueChanged}
                        defaultValue={selectedMonth}
                        height={40} 
                        />
                    </div>
                  </div>
                  <div className="dx-field">
                    <div className="dx-field-label">Year</div>
                    <div className="dx-field-value">
                      <SelectBox dataSource={years}
                        displayExpr="name"
                        valueExpr="name"
                        onValueChanged={onYearValueChanged}
                        defaultValue={selectedYear} 
                        height={40}
                        />
                    </div>
                  </div>
                </>}
              {dateModalFilter === 2 &&
                <>
                  <div className="dx-field">
                    <div className="dx-field-label">Year</div>
                    <div className="dx-field-value">
                      <SelectBox dataSource={years}
                        displayExpr="name"
                        valueExpr="name"
                        onValueChanged={onYearValueChanged}
                        defaultValue={selectedYear}
                        height={40}
                      />
                    </div>

                    {/* <DropDownButton
                      width={200}
                      items={years}
                      keyExpr="id"
                      text={selectedYear.toString()}
                      displayExpr="name"
                      useSelectMode={true}
                      onSelectionChanged={onYearValueChanged}
                      splitButton={true}
                      dropDownOptions={dropDownOptions}
                      height={27}
                      selectedItemKey={years[0].id} 
                    />*/}
                  </div>
                </>
              }
              <div className='done-button-container'>
                <Button className='done-button' onClick={setModalDate}>Done</Button>
              </div>
            </CModalBody>
          </CModal>
        </>
      </div>
    </React.Fragment>
  );
}

