import React from 'react';
import './Footer.scss';
import Toolbar, { Item } from 'devextreme-react/toolbar';
import Button from 'devextreme-react/button';
import UserPanel from '../user-panel/UserPanel';
import { Template } from 'devextreme-react/core/template';

export default function Footer({ ...rest }) {
  return (
    <Toolbar className={'footer-component'} {...rest}>
      <Item>
        <span style={{color:"lightgray"}}>©Merit Technology</span>
      </Item>
      {/* <Item
        location={'after'}
        locateInMenu={'auto'}
        menuItemTemplate={'userPanelTemplate'}
      >
        <Button
          className={'user-button authorization'}
          width={150}
          height={'100%'}
          stylingMode={'text'}
        >
          <UserPanel menuMode={'context'} />
        </Button>
      </Item>
      <Template name={'userPanelTemplate'}>
        <UserPanel menuMode={'list'} />
      </Template> */}
    </Toolbar>
  );
}
