import React, { useState, useEffect } from 'react';
import DataGrid, {
  Column,
  Selection,Paging
} from "devextreme-react/data-grid";
import { CWidgetStatsA } from "@coreui/react";
import {
  CRow,
  CCol,
} from "@coreui/react";

import { useNavigate } from 'react-router-dom';
import { Config } from '../../config/config';
import { useGlobal } from "../../contexts/global";

import type { GameTypes } from '../../types.js';
import { LoadIndicator } from 'devextreme-react/load-indicator';
import dayjs from 'dayjs'
import { useAuth } from '../../contexts/auth';

interface GridState {
  showEmployeeInfo: boolean;
  selectedRowPicture: string;
  selectedRowNotes: string;
}

export default function LiveGame({ }: React.PropsWithChildren<GridState>) {
  const { currentCasino, dateRange } = useGlobal();
  const { accessToken } = useAuth();

  const [gameTypes, setGameTypes] = useState<GameTypes[]>([{
    gameTypeId: 0,
    gameType: "",
    tableDrop: 0,
    result: 0,
    marker: 0,
  }]);
  const [totalDrop, setTotalDrop] = useState<number>(0);
  const [totalResult, setTotalResult] = useState<number>(0);

  const [loadIndicatorVisible, setloadIndicatorVisible] = useState<boolean>(false);

  const navigate = useNavigate();

  const onSelectionChanged = ({ selectedRowsData }: { selectedRowsData: any[] }) => {
    const data = selectedRowsData[0];
    navigate(`/game?gameTypeId=${data.gameTypeId}&casinoId=${currentCasino?.casinoId}`, { state: { data } })
  }

  function formattedData(data: string) {
    const format = data?.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    return format;
  }

  //Get Game Types
  async function getGameTypes(casinoId: number): Promise<GameTypes> {
    setloadIndicatorVisible(true);
    const headers: Headers = new Headers()
    headers.set('Content-Type', 'application/json')
    headers.set('Accept', 'application/json')
    headers.set('Authorization', `Bearer ${accessToken}`)

    const request: RequestInfo = new Request(`${Config.ApiBaseUrl}/game/getgametypestotal?casinoId=${casinoId}&reportStartDate=${dayjs(dateRange[0]).format('YYYY-MM-DD')}&reportEndDate=${dayjs(dateRange[1]).format('YYYY-MM-DD')}`, {
      method: 'GET',
      headers: headers
    })

    const res = await fetch(request);
    const response = await res.json();
    setGameTypes(response);
    setTotalDrop(response[0]?.tableDropTotal);
    setTotalResult(response[0]?.resultTotal);
    setloadIndicatorVisible(false);
    return response as GameTypes;
  }

  useEffect(() => {
    if (currentCasino != null && currentCasino.casinoId !== 0) {
      getGameTypes(currentCasino.casinoId);
    }
  }, [currentCasino, dateRange])

  return (
    <React.Fragment>
      {(loadIndicatorVisible === false) ?
        (
          <div style={{ marginBottom: 60 }}>
            <br />
            <CRow>
              <CCol xs={6} sm={6} lg={6}>
                <CWidgetStatsA
                  className="mb-4 pb-3"
                  color="primary"
                  value={
                    <span style={{ fontSize: 16}}>
                      $ {totalDrop ? formattedData(totalDrop.toFixed(0)) : 0}
                    </span>
                  }
                  title="Drop"
                />
              </CCol>
              <CCol xs={6} sm={6} lg={6}>
                <CWidgetStatsA
                  className="mb-4 pb-3"
                  color="info"
                  value={
                    <span style={{ fontSize: 16}}>
                      $ {totalResult ? formattedData(totalResult.toFixed(0)) : 0}
                    </span>
                  }
                  title="Result"
                />
              </CCol>
            </CRow>

            <br />
            <DataGrid
              id="grid"
              dataSource={gameTypes}
              keyExpr="gameTypeId"
              columnAutoWidth={true}
              allowColumnReordering={false}
              showBorders={true}
              onSelectionChanged={onSelectionChanged}
              hoverStateEnabled={true}
              height="100%"
              scrolling={{ mode: 'virtual', showScrollbar: 'never' }}
              onRowPrepared={(e) => {
                if (e.rowType === 'data') {
                  e.rowElement.style.cursor = 'pointer';
                  e.rowElement.addEventListener('mouseenter', () => {
                    e.rowElement.style.backgroundColor = '#f5f5f5'; // İstediğiniz arka plan rengini ayarlayabilirsiniz
                  });
                  e.rowElement.addEventListener('mouseleave', () => {
                    e.rowElement.style.backgroundColor = ''; // Rengi varsayılan haline getirir
                  });
                }
              }}            
            >
              <Paging defaultPageSize={8} />
              <Selection mode="single" />
              <Column
                dataField="gameType"
                caption="Game Type"
                format="fixedPoint"
              />
              <Column dataField="tableDrop" caption="Drop" format="fixedPoint" />
              <Column dataField="result" caption="Result" format="fixedPoint" />
            </DataGrid>
          </div>
        ) : (
          <div style={{ textAlign: 'center', marginTop: 40 }}>
            <LoadIndicator id="medium-indicator" height={40} width={40} />
          </div>
        )}
    </React.Fragment>
  );
}

