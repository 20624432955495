import React, { useState, useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import appInfo from './app-info';
import routes from './app-routes';
import { SideNavOuterToolbar as SideNavBarLayout } from './layouts';
import { useGlobal } from './contexts/global';
import { Casino } from './types';
// import { Footer } from './components';
import { LoadIndicator } from 'devextreme-react/load-indicator';

const Content: React.FC = () => {
  const { currentCasino } = useGlobal();

  return (
    <SideNavBarLayout title={appInfo.title} name={currentCasino?.name ?? ""} updateName={(v) => { }}>
      {
        currentCasino != undefined && currentCasino.activeDate != new Date(1900, 1, 1) ? (
          <Routes>
            {routes.map(({ path, element }) => (
              <Route
                key={path}
                path={path}
                element={element}
              />
            ))}
            <Route
              path='*'
              element={<Navigate to='/home' />}
            />
          </Routes>
        ) : (
          <div style={{ textAlign: 'center', marginTop: 40 }}>
            <LoadIndicator id="medium-indicator" height={40} width={40} />
          </div>
        )
      }

      {/* <Footer>
       
      </Footer> */}
    </SideNavBarLayout>
  );
}

export default Content;