import 'devextreme/dist/css/dx.common.css';
import './themes/generated/theme.base.css';
import './themes/generated/theme.additional.css';
// import '@coreui/coreui/dist/css/coreui.min.css';
import React, { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { HashRouter } from 'react-router-dom';
import './dx-styles.scss';
import LoadPanel from 'devextreme-react/load-panel';
import { NavigationProvider } from './contexts/navigation';
import { AuthProvider, useAuth } from './contexts/auth';
import { useScreenSizeClass } from './utils/media-query';
import Content from './Content';
import UnauthenticatedContent from './UnauthenticatedContent';
import './scss/style.scss';
import { GlobalProvider, useGlobal } from './contexts/global';

function App() {
  const { user, authUser, loading, accessToken } = useAuth();

  useEffect(() => {

  }, [accessToken])

  if (loading) {
    return <LoadPanel visible={true} />;
  }

  if (accessToken != null && accessToken.length > 0) {
    return <Content />;
  }

  return <UnauthenticatedContent />;
}
export default function Root() {
  const screenSizeClass = useScreenSizeClass();

  return (
    <HashRouter>
      <AuthProvider>
        <GlobalProvider>
          <NavigationProvider>
            <div className={`app ${screenSizeClass}`}>
              <App />
            </div>
          </NavigationProvider>
        </GlobalProvider>
      </AuthProvider>
    </HashRouter>
  );
}
