import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Toolbar, { Item } from 'devextreme-react/toolbar';
import Button from 'devextreme-react/button';
import './Header.scss';
import DropDownButton from "devextreme-react/drop-down-button";
import { CDateRangePicker } from '@coreui/react-pro';
import { useGlobal } from '../../contexts/global';
import { HeaderProps } from '../../types';
import { useScreenSize } from '../../utils/media-query';
import { CModal, CModalHeader, CModalTitle, CModalBody } from '@coreui/react';
import dayjs from 'dayjs'

export default function Header({ menuToggleEnabled, toggleMenu }: HeaderProps) {
  const {
    casinos,
    currentCasino,
    changeCasino,
    dateRange,
    setDateRange,
  } = useGlobal();

  const { isXSmall, isSmall } = useScreenSize();
  const [visibleSm, setVisibleSm] = useState(false);
  const [dateType, setDateType] = useState<string>("");
  const [start, setStart] = useState<Date>();
  const [end, setEnd] = useState<Date>();

  const location = useLocation()

  useEffect(() => {
    if (!dateRange[0] || !dateRange[1]) {
      const activeCasinoDate = dayjs(currentCasino?.activeDate, 'YYYY-MM-DD').toDate();
      setDateRange([activeCasinoDate, activeCasinoDate]);
      setStart(activeCasinoDate)
      setEnd(activeCasinoDate)
    }
  }, [dateRange]);

  useEffect(() => {
    if (casinos == undefined || casinos.length == 0) return;

    let casinoId = parseInt(localStorage.getItem("currentCasinoId") ?? '');
    if (isNaN(casinoId)) {
      casinoId = casinos[0].casinoId;
      //console.log("Casino Id0",casinoId);
      if (location.pathname.indexOf('/home') > -1 || location.pathname.indexOf('/customer') > -1) {
        changeCasino(casinoId);
      } else if (location.pathname.indexOf('/status') > -1 || location.pathname.indexOf('/credit') > -1) {
        changeCasino(casinos[2].casinoId);
      }
      else if (location.pathname.indexOf('/livegame') > -1 || location.pathname.indexOf('/game') > -1 || location.pathname.indexOf('/game/table') > -1) {
        changeCasino(casinos[2].casinoId);
      }
    } else {
      if (location.pathname.indexOf('/home') > -1 || location.pathname.indexOf('/customer') > -1) {
        changeCasino(casinos[0].casinoId);
      } else if (location.pathname.indexOf('/status') > -1 || location.pathname.indexOf('/credit') > -1) {
        changeCasino(casinoId);
      }
      else if (location.pathname.indexOf('/livegame') > -1 || location.pathname.indexOf('/game') > -1 || location.pathname.indexOf('/game/table') > -1) {
        changeCasino(casinoId);
      }
    }
  }, [casinos, location.pathname]);

  const getActiveCasinoDate = () => {
    let casinoDate = currentCasino?.activeDate;

    // Geçerli bir tarih olup olmadığını ve geçerli bir tarih olmadığında varsayılan tarihi kontrol et
    if (!casinoDate || isNaN(new Date(casinoDate).getTime()) || new Date(casinoDate).getFullYear() < 1000) {
      casinoDate = new Date();
    }

    return dayjs(casinoDate).startOf('day');
  }

  function onCasinoChanged(e: any) {
    const selectedId = e.item.casinoId;
    changeCasino(selectedId);
  }

  const updateDateType = (type: string): void => {
    setDateType(type);
  }

  const setToday = (): void => {
    const activeCasinoDate = getActiveCasinoDate().toDate();
    setStart(activeCasinoDate);
    setEnd(activeCasinoDate);
  }

  const setYesterday = (): void => {
    const yesterday = getActiveCasinoDate().subtract(1, 'day').toDate();
    setStart(yesterday);
    setEnd(yesterday);
  }

  const setLastSeven = (): void => {
    const activeCasinoDate = getActiveCasinoDate();
    const lastSevenStart = activeCasinoDate.subtract(7, 'day').toDate();
    const lastSevenEnd = activeCasinoDate.toDate();
    setStart(lastSevenStart);
    setEnd(lastSevenEnd);
  }

  const setLastThirty = (): void => {
    const activeCasinoDate = getActiveCasinoDate();
    const lastThirtyStart = activeCasinoDate.subtract(30, 'day').toDate();
    const lastThirtyEnd = activeCasinoDate.toDate();
    setStart(lastThirtyStart);
    setEnd(lastThirtyEnd);
  }

  const setThisMonth = (): void => {
    const activeCasinoDate = getActiveCasinoDate();
    const firstDayOfMonth = activeCasinoDate.startOf('month').toDate();
    const lastDayOfMonth = activeCasinoDate.endOf('month').toDate();
    setStart(firstDayOfMonth);
    setEnd(lastDayOfMonth);
  }

  const setLastMonth = (): void => {
    const activeCasinoDate = getActiveCasinoDate();
    const firstDayOfLastMonth = activeCasinoDate.subtract(1, 'month').startOf('month').toDate();
    const lastDayOfLastMonth = activeCasinoDate.subtract(1, 'month').endOf('month').toDate();
    setStart(firstDayOfLastMonth);
    setEnd(lastDayOfLastMonth);
  }

  const setModalDate = () => {
    let updatedDateType = "";

    if (start && end) {
      setDateRange([start, end]);

      // Kontrol et ve uygun tarih aralığına göre updatedDateType'ı ayarla
      if (dayjs(start).isSame(end, 'day')) {
        updatedDateType = `${dayjs(start).format('DD.MM.YYYY')}`;
      } else {
        updatedDateType = `${dayjs(start).format('DD.MM.YYYY')}-${dayjs(end).format('DD.MM.YYYY')}`;
      }
    } else {
      // Özel tarih aralığı seçilmemişse, tarih aralığı butonlarından birini kontrol et
      if (dateType === "Today" || dateType === "Yesterday" || dateType === "Last 7 Days" || dateType === "Last 30 Days" || dateType === "This Month" || dateType === "Last Month") {
        updatedDateType = dateType;
      } else {
        // Varsayılan olarak "Today" kullan
        updatedDateType = "Today";
      }
    }

    updateDateType(updatedDateType);
    setVisibleSm(false);
  }



  return (
    <header className={'header-component'}>
      <Toolbar className={'header-toolbar'}>
        <Item
          visible={menuToggleEnabled}
          location={'before'}
          widget={'dxButton'}
          cssClass={'menu-button'}
        >
          <Button icon="menu" stylingMode="text" onClick={toggleMenu} />
        </Item>
        <Item location={"before"}>
          <DropDownButton
            width={150}
            items={casinos}
            keyExpr="casinoId"
            //text={selectedCasinoName}
            displayExpr="name"
            useSelectMode={true}
            onSelectionChanged={onCasinoChanged}
            splitButton={false}
            dropDownOptions={{ width: 150 }}
            className='casinos'
            height={25}
            selectedItemKey={currentCasino?.casinoId}

          />
        </Item>
        {/* Date Range */}

        <Item location={'after'}>
          {location.pathname === '/home' || location.pathname === '/livegame' || location.pathname.indexOf('/game') > -1 || location.pathname === '/game/table' ?
            <>
              {/* {!isXSmall ?
                <Button icon="event" text={dateType ? dateType : `${dayjs(dateRange[0]).format('DD.MM.YYYY')}`} stylingMode="outlined" onClick={() => setVisibleSm(!visibleSm)} height={30} />
                :
                <Button icon="event" onClick={() => setVisibleSm(!visibleSm)} />
              } */}

              <Button text={dateType ? dateType : `${dayjs(dateRange[0]).format('DD.MM.YYYY')}`} stylingMode="outlined" onClick={() => setVisibleSm(!visibleSm)} height={25}/>
              <CModal fullscreen="sm" visible={visibleSm} onClose={setModalDate}>
                {/* <CModalHeader>
                  <CModalTitle>Select date range</CModalTitle>
                </CModalHeader> */}
                <CModalBody>
                  <CModalTitle style={{fontSize:20}}>Select Date Range</CModalTitle>
                  <hr />
                  <div className='date-picker-container'>
                    <CDateRangePicker
                      startDate={start}
                      endDate={end}
                      calendars={1}
                      className={'custom-date-range-picker'}
                      onStartDateChange={(date: Date | null) => {
                        if (date !== null) {
                          setStart(date);
                        }
                      }}
                      onEndDateChange={(date: Date | null) => {
                        if (date !== null) {
                          setEnd(date)
                        }
                      }}
                    />
                  </div>
                  <div className='date-buttons-container'>
                    <Button className='date-button' onClick={setToday}>Today</Button>
                    <Button className='date-button' onClick={setYesterday}>Yesterday</Button>
                    <Button className='date-button' onClick={setLastSeven}>Last 7 Days</Button>
                    <Button className='date-button' onClick={setLastThirty}>Last 30 Days</Button>
                    <Button className='date-button' onClick={setThisMonth}>This Month</Button>
                    <Button className='date-button' onClick={setLastMonth}>Last Month</Button>
                  </div>
                  <div className='done-button-container'>
                    <Button className='done-button' onClick={setModalDate}>Done</Button>
                  </div>
                </CModalBody>

              </CModal>
            </>
            : null}
        </Item>

      </Toolbar>
    </header>
  )
}